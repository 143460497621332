<template>
  <div class="wrapper-paddings">
    <div class="componentWrapper">
      <h3 class="componentTitle">{{componentTitle}}</h3>
      <div class="midSectionContainer">
        <div class="refDate date-style" v-if="refDate">{{refDate}}</div>
        <div class="refDate date-style" v-if="topComment && dataDisplayMode === 'tab'">{{topComment}}</div>
        <div class="refDate date-style" v-else></div>

        <div class="switchContainer">
<!--          <div class="switchItem switchTab" :disabled="!dataReady" @click="displayDataSwitch('tab')" :class="dataDisplayMode === 'tab' ? 'activeSwitch' : ''">{{ switchTableBtn }}</div>-->
<!--          <div class="switchItem switchChart" :disabled="!dataReady" @click="displayDataSwitch('chart')" :class="dataDisplayMode === 'chart' ? 'activeSwitch' : ''">{{ switchChartBtn }}</div>-->
          <!--        <div class="exportMenuContainer">-->
          <!--          <slot name="componentExportMenu" class="exportMenu">...</slot>-->
          <!--        </div>-->
        </div>
      </div>

      <div class="componentChartContainer componentDataContainer" v-show="dataDisplayMode === 'chart'">
        <slot name="componentChart" class="componentChart"></slot>
      </div>
      <div style="text-align: center; color: #333333; font-weight: 300; font-size: 12px" v-if="legendNotes">{{ legendNotes }}</div>
      <div class="componentTabContainer componentDataContainer" v-show="dataDisplayMode === 'chart'">
        <slot name="componentTab" class="componentTab"></slot>
      </div>
<!--   color: #AA192F    -->
      <div v-if="notes" style="color: #666666; padding-top: 32px">{{ notes }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props:["componentTitle", "refDate", "topComment", "dataReady", "notes", "legendNotes"
    // "switchTableBtn", "switchChartBtn"
  ],

  data() {
    return {
      dataDisplayMode: 'chart',
    }
  },

  methods: {
    displayDataSwitch(value) {
      this.dataDisplayMode = value;
      this.$emit("changeDisplayDataChild", this.dataDisplayMode);
    }
  }
}
</script>

<style lang="scss" scoped>

/***************
* # component
***************/

//.wrapper-paddings {
//  padding: 10px;
//}

.activeSwitch {
  position: relative;
  color: #AA192F !important;
  font-weight: 600 !important;
}

.activeSwitch::after {
  content: "";
  display: block;
  background-color: #AA192F !important;
  width: 37px;
  height: 1px;
  position: absolute;
  bottom: 0px;
}
//420->531
.componentWrapper {
  //background-color: white;
  //border-radius: 11px;
  //border: none;
  //padding: 21px 32px;
  //margin: 10px;
  //height: 531px !important;
  //background-color: green;
  //
  //@media screen and (max-width: 1024px) {
  //  background-color: lightblue;
  //  height: 450px !important;
  //}
  //@media screen and (max-width: 768px) {
  //  background-color: orange;
  //  height: 300px !important;
  //}

  //@media screen and (max-width: 768px) {
  //  background-color: orange;
  //}
  //@media screen and (min-width: 1024px) {
  //  background-color: lightblue;
  //}

  //font-family: Nunito, sans-serif;

  //.componentTitle {
  //  font-weight: 600;
  //  font-size: 20px;
  //  line-height: 17px;
  //  color: #000;
  //  margin-top: 0;
  //  margin-bottom: 6px;
  //}

  .midSectionContainer {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 32px;
    margin-bottom: 22px;
    margin-top: 10px;


    .switchContainer {
      display: flex;

      .switchItem {
        margin-left: 25px;
        padding: 0 5px;

        font-weight: 400;
        line-height: 19px;
        font-size: 14px;
        color: #666666;
      }

      .switchItem:hover {
        cursor: pointer;
      }

      .exportMenuContainer {
        margin-left: 32px;
      }
    }


  }
  //
  //.componentDataContainer > * { // sostituito da controllo su pagina
  //  height: 412px;
  //}

}
@media screen and (max-width: 1024px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      margin-bottom: 24px;
      margin-top: 5px;

    }

  }
}
@media screen and (max-width: 768px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      margin-bottom: 24px;
      margin-top: 5px;

      .switchContainer {

        .switchItem {

          font-size: 12px;
        }

      }



    }

  }
}
</style>