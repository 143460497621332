<template>
    <div class="placeholder">
      <slot name="componentName">component name</slot>
      <slot name="componentButtons"></slot>
      <slot name="componentData">data needed for the component</slot>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .placeholder {
        background-color: white;
        border-radius: 11px;
        border: none;
        padding: 21px 32px;
        margin: 10px;
    }
</style>