<template>
  <div class="custom-wrapper">

    <!-- # RESPONSIVE BOX SETTINGS -->

    <DxResponsiveBox :screen-by-width="screenByWidth" single-column-screen="xs sm md" id="responsive-box" width="100%">
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />

      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />

      <!-- # FILTERS -->
      <DxItem class="category-list-selector">
        <DxLocation :row="0" :col="0" :colspan="6" screen="xs sm md lg" />
        <template #default >
          <div class="filterContainer">

            <!-- # STORE FILTER -->
            <div class="storeSelectorContainer">
              <!--                          temp-->
              <DxSelectBox style="font-family: 'Nunito'"
                           :dataSource="getOutletsList.filter(item => item.id != 0)"
                           displayExpr= "name"
                           :show-clear-button="false"
                           :placeholder="$store.state.selectedOutlet.name"
                           value-expr="id"
                           :onValueChanged="onOutletSelected"
                           styling-mode="underlined"
                           :disabled="!pageDataReady || productCategoryFilterDisabledByPage"
              />
              <!--                     v-model="selectedOutlet"-->

            </div>

            <div class="filtersContainer">
              <!-- # CALENDAR FILTER -->
              <!--              <div class="display-calendar-btn" @click="onShowCalendar">{{ displayStringDate }}</div>-->
<!--              <div class="display-calendar-btn">{{ (new Date($store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(0,4), ($store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(4,6) - 1), $store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + $store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(0,4) }}</div>-->
              <div class="display-calendar-btn" style="cursor: default !important; opacity: 0.6">{{ (new Date(String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4), (String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)), String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4) }}</div>

              <!--                            <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? $store.state.selectedDate : $store.state.outletLastSalesDates.salesLastDayClosedMonth }}</div>-->
              <div :class="['calendarContainer', calendarHidden ? 'calendarHidden' : '']">
                <div class="closeCalendarBtn" @click="onShowCalendar">x</div>
                <div class="calendarButton">
                  <!--                  <div class="calendarButtonTitle">{{ LABELS.EN.FILTERS.CALENDARRANGETITLE }}</div>-->
                  <button v-for="(dateBtn, ix) in [{name: 'month', value: 'year'}, {name: 'week', value: 'month'}]" :key="ix"
                          :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                          @click="onCalendarZoomLevelSelection(dateBtn)"
                          :disabled="dateBtn.value === 'month'"
                  >{{ dateBtn.name }}</button>
                </div>
                <div class="calendarComponentContainer">
                  <DxCalendar
                      @value-changed="onSelectedDate"
                      :zoom-level="calendarZoomLevel"
                      :max-zoom-level="calendarZoomLevel"
                      :max="maxSelectableDate"
                      :min="minSelectableDate"
                  >
                    <!--                        :disabled-dates="disabledDates"-->
                    <!--                        :value="(this.$store.state.selectedDate === null ? this.$store.state.outletLastSalesDates.salesLastDay : this.$store.state.selectedDate)"-->
                    <!--                        v-model="currentValue"-->
                    <!--                        :min="minDateValue"-->
                    <!--                        :max="maxDateValue"-->
                    <!--                        :disabled-dates="disabledDates"-->
                    <!--                        :first-day-of-week="firstDay"-->
                    <!--                        :disabled="disabled"-->
                    <!--                        :cell-template="cellTemplate"-->
                    <!--                        <template #custom="{ data: cell }">-->
                    <!--                          <span :class="getCellCssClass(cell.date)">-->
                    <!--                            {{ cell.text }}-->
                    <!--                          </span>-->
                    <!--                        </template>-->
                  </DxCalendar>
                </div>
              </div>

              <!-- # TENANTS FILTER -->
              <div class="categoriesListSelectorContainer" style="width: 350px; font-family: 'Nunito'">
                <DxSelectBox style="font-family: 'Nunito'"
                             :dataSource="getTenantsList"
                             displayExpr= "name"
                             :placeholder="loc.FILTERS.TENANTSELECTPLACEHOLDER"
                             value-expr="id"
                             :onValueChanged="onTenantSelected"
                             :show-clear-button="true"
                             styling-mode="underlined"
                             :search-enabled="true"
                             :disabled="!pageDataReady"
                             :value="$store.state.selectedTenant"
                />
                <!--                #n-->
                <!--                       v-model="selectedCategory"-->
                <!--                    <DxTextBoxButton name="clear" :options="{ }"/>-->
              </div>

            </div>
          </div>
        </template>
      </DxItem>


      <!-- ############## 1st row ############## -->
      <!--   TEXT   -->
      <DxItem class="item text">
        <DxLocation :row="1" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="1" :col="0" :colspan="1" :rowspan="2" screen="lg" />
        <template #default >
          <advanced-text-visualizer
              :component-title="$store.state.selectedTenant"
              :section-one-title="loc.TENANT.OVERVIEWSEC1TITLE"
              :section-two-title="loc.TENANT.OVERVIEWSEC2TITLE"
              :data="scatterDatagridERLTM.dataOverviewFilteredByTenant"
          />
        </template>
      </DxItem>

      <!--   CHART-DATAGRID   -->
      <DxItem>
        <DxLocation :row="2" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
        <template #default>
          <div style="position: relative;">

            <DxButton
                :style="`display: ${dataDisplayMode == 'chart' ? 'block' : 'none'}`"
                id="reset-zoom"
                class="reset-zoom-btn"
                :on-click="resetScatterErZoom1"
                :text="loc.COMMON.RESETZOOMTEXT"
            />

            <basic-chart-datagrid-visualizer
                :componentTitle="loc.TENANT.NETSALESTITLE"
                :refDate="null"
                :dataReady="pageDataReady"
                :switch-table-btn="loc.TENANT.CHARTDATAGRIDSWITCHTABLE"
                :switch-chart-btn="loc.TENANT.CHARTDATAGRIDSWITCHCHART"
                @changeDisplayDataChild="changeDisplayData($event)"
            >

              <!-- ## LINE CHART -->
              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         id="scatterChart-filtered-by-client"
                         :data-source="scatterDatagridERLTM.dataFilteredByProductCategory"
                         class="scatterChart"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance1"
                >
                  <DxZoomAndPan
                      :drag-to-zoom="true"
                      pan-key="ctrl"
                      argument-axis="both"
                      value-axis="both"
                      :allow-touch-gestures="true"
                  />
                  <DxCommonSeriesSettings type="line" />
                  <DxSeries argument-field="periodOrdinal" value-field="salesPPY" :color="getOutletStyle.tertiaryColor" :name="displayPPreviousYear">
                    <DxPoint :color="getOutletStyle.tertiaryColor" :size="scatterERLTM.points3.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="periodOrdinal" value-field="salesPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="periodOrdinal" value-field="sales" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>
                  <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipVisitors.argumentFormat" :format="scatterERLTM.tooltipVisitors.valueFormat" />

                  <DxArgumentAxis :tick-interval="1" :title="loc.TENANT.LINECHARTARGAXISTITLE" :label="{ customizeText: newArgAxisValue }">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>
                  <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                  <!--                      todo: togliere primo e ultimo asse di argument grid -->
                  <DxValueAxis
                      :grid="{ visible: true }"
                      :title="loc.TENANT.LINECHARTVALUEAXISTITLE"
                      :visible="false"
                  />
                  <DxLegend
                      :visible="true"
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :margin="{ top: 25 }"
                      itemTextPosition="right"
                      :font="getLegendFontSettings"
                  />
                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateFootfall="{ data }">
                    <div style="text-align: center">
                      Net Sales: € {{ data.valueText }}<br>
                      Month: {{ (data.point.series.getValueFields() == "sales") ? data.point.data.periodPlainAlt : ((data.point.series.getValueFields() == "salesPY") ? data.point.data.periodPlainAltPY : data.point.data.periodPlainAltPPY)}}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>
                <!-- ## DATAGRID -->
                <DxDataGrid
                    id="dataGrid-filtered-by-client"
                    class="datagrid"
                    :data-source="scatterDatagridERLTM.dataFilteredByProductCategoryDatagrid"
                    key-expr="periodOrdinal"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                    :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />
                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="periodOrdinal"
                      :caption="loc.TENANT.DATAGRIDNETSALESCOL0CAPTION"
                      data-type="number"
                      alignment="left"
                      cell-template="ordinal-to-month"
                      sort-order="asc"
                  />
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="sales"
                      :caption="loc.TENANT.DATAGRIDNETSALESCOL1CAPTION + displayCurrentYear + ' (€)'"
                      data-type="number"
                      alignment="left"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="varSalesCYvsPY"
                      :caption="loc.TENANT.DATAGRIDNETSALESCOL2CAPTION"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataPercentage"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="salesPY"
                      :caption="loc.TENANT.DATAGRIDNETSALESCOL3CAPTION + displayPreviousYear + ' (€)'"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataDecimals"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="varSalesPYvsPPY"
                      :caption="loc.TENANT.DATAGRIDNETSALESCOL4CAPTION"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataPercentage"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="salesPPY"
                      :caption="loc.TENANT.DATAGRIDNETSALESCOL5CAPTION + displayPPreviousYear + ' (€)'"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataDecimals"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxColumn>

                  <!-- template personalizzato celle datagrid -->
                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data"/>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                </DxDataGrid>
              </template>


            </basic-chart-datagrid-visualizer>
          </div>

        </template>
      </DxItem>

      <!-- ############## 2nd row ############## -->
      <!--   CHART-DATAGRID   -->
      <!-- <DxItem>
        <DxLocation :row="3" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
        <template #default>
          <div style="position: relative;">

            <basic-chart-datagrid-visualizer
                :componentTitle="loc.TENANT.INVOICEDTITLE"
                :refDate="loc.TENANT.INVOICEDDESCRIPTION"
                :dataReady="pageDataReady"
                :switch-table-btn="loc.TENANT.CHARTDATAGRIDSWITCHTABLE"
                :switch-chart-btn="loc.TENANT.CHARTDATAGRIDSWITCHCHART"
            >

     
              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         id="scatterChart-filtered-by-client"
                         :data-source="scatterDatagridERLTM.dataInvoicedAmountsByTenant"
                         class="scatterChart"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance0"
                >

                  <DxCommonSeriesSettings type="bar" />
                  <DxSeries argument-field="plain" value-field="amountPPY" :color="getOutletStyle.tertiaryColor" :name="displayPPreviousYear">
                    <DxPoint :color="getOutletStyle.tertiaryColor" :size="scatterERLTM.points3.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="amountPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="amount" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>
                  <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipVisitors.argumentFormat" :format="scatterERLTM.tooltipVisitors.valueFormat" />

                  <DxArgumentAxis :tick-interval="1">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>

                  <DxValueAxis
                      :grid="{ visible: true }"
                      title=""
                      :visible="false"
                  />
                  <DxLegend
                      :visible="true"
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :margin="{ top: 25 }"
                      itemTextPosition="right"
                      :font="getLegendFontSettings"
                  />
                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateFootfall="{ data }">
                    <div style="text-align: center">
                      Amount: € {{ data.valueText }}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>

                <DxDataGrid
                    id="dataGrid-filtered-by-client"
                    class="datagrid"
                    :data-source="scatterDatagridERLTM.dataInvoicedAmountsByTenant"
                    key-expr="amountType"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                    :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />
                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="plain"
                      :caption="loc.TENANT.DATAGRIDINVOICEDCOL0CAPTION"
                      data-type="string"
                      alignment="left"
                      cell-template="diff-cell-template"
                      :allow-sorting="false"
                  />
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amount"
                      :caption="displayCurrentYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPY"
                      :caption="displayPreviousYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPPY"
                      :caption="displayPPreviousYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxColumn>

                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data"/>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                </DxDataGrid>
              </template>

            </basic-chart-datagrid-visualizer>
          </div>

        </template>
      </DxItem> -->

      <!-- ############## 3rd row ############## -->
      <!--   CHART-DATAGRID   -->
      <DxItem>
        <DxLocation :row="3" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
        <template #default>
          <div style="position: relative;">

            <basic-chart-datagrid-visualizer
                :componentTitle="loc.TENANT.INVOICEDSQMTITLE"
                :refDate="loc.TENANT.INVOICEDDESCRIPTION"
                :dataReady="pageDataReady"
                :switch-table-btn="loc.TENANT.CHARTDATAGRIDSWITCHTABLE"
                :switch-chart-btn="loc.TENANT.CHARTDATAGRIDSWITCHCHART"
            >

              <!-- ## LINE CHART -->
              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         id="scatterChart-filtered-by-client"
                         :data-source="scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant"
                         class="scatterChart"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance0"
                >

                  <DxCommonSeriesSettings type="bar" />
                  <DxSeries argument-field="plain" value-field="amountPPY" :color="getOutletStyle.tertiaryColor" :name="displayPPreviousYear">
                    <DxPoint :color="getOutletStyle.tertiaryColor" :size="scatterERLTM.points3.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="amountPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="amount" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>
                  <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipCr.argumentFormat" :format="scatterERLTM.tooltipCr.valueFormat" />

                  <DxArgumentAxis :tick-interval="1">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>
                  <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                  <!--                      todo: togliere primo e ultimo asse di argument grid -->
                  <DxValueAxis
                      :grid="{ visible: true }"
                      title=""
                      :visible="false"
                  />
                  <DxLegend
                      :visible="true"
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :margin="{ top: 25 }"
                      itemTextPosition="right"
                      :font="getLegendFontSettings"
                  />
                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateFootfall="{ data }">
                    <div style="text-align: center">
                      Amount: € {{ data.valueText }}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>
                <!-- ## DATAGRID -->
                <DxDataGrid
                    id="dataGrid-filtered-by-client"
                    class="datagrid"
                    :data-source="scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant"
                    key-expr="amountType"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                    :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />
                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="plain"
                      :caption="loc.TENANT.DATAGRIDINVOICEDCOL0CAPTION"
                      data-type="string"
                      alignment="left"
                      cell-template="diff-cell-template"
                      :allow-sorting="false"
                  />

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amount"
                      :caption="displayCurrentYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountTOT"
                      :caption="`${displayCurrentYear} TOT`"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPY"
                      :caption="displayPreviousYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPYTOT"
                      :caption="`${displayPreviousYear} TOT`"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>

                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPPY"
                      :caption="displayPPreviousYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPPYTOT"
                      :caption="`${displayPPreviousYear} TOT`"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  
                  <!-- template personalizzato celle datagrid -->
                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data"/>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                </DxDataGrid>
              </template>


            </basic-chart-datagrid-visualizer>
          </div>

        </template>
      </DxItem>

      <!-- ############## 4th row ############## -->
      <!--   CHART-DATAGRID   -->
      <DxItem>
        <DxLocation :row="4" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
        <template #default>
          <div style="position: relative;">

            <basic-chart-datagrid-visualizer
                :componentTitle="loc.TENANT.RENTTITLE"
                :refDate="null"
                :dataReady="pageDataReady"
                :switch-table-btn="loc.TENANT.CHARTDATAGRIDSWITCHTABLE"
                :switch-chart-btn="loc.TENANT.CHARTDATAGRIDSWITCHCHART"
            >

              <!-- ## LINE CHART -->
              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         id="scatterChart-filtered-by-client"
                         :data-source="scatterDatagridERLTM.dataContractualAmountsSqmByTenant"
                         class="scatterChart"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance0"
                >

                  <DxCommonSeriesSettings type="bar" />
                  <DxSeries argument-field="plain" value-field="amountPPY" :color="getOutletStyle.tertiaryColor" :name="displayPPreviousYear">
                    <DxPoint :color="getOutletStyle.tertiaryColor" :size="scatterERLTM.points3.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="amountPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="amount" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>
                  <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipCr.argumentFormat" :format="scatterERLTM.tooltipCr.valueFormat" />

                  <DxArgumentAxis :tick-interval="1">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>
                  <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                  <!--                      todo: togliere primo e ultimo asse di argument grid -->
                  <DxValueAxis
                      :grid="{ visible: true }"
                      title=""
                      :visible="false"
                  />
                  <DxLegend
                      :visible="true"
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :margin="{ top: 25 }"
                      itemTextPosition="right"
                      :font="getLegendFontSettings"
                  />
                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateFootfall="{ data }">
                    <div style="text-align: center">
                      Amount: € {{ data.valueText }}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>
                <!-- ## DATAGRID -->
                <DxDataGrid
                    id="dataGrid-filtered-by-client"
                    class="datagrid"
                    :data-source="scatterDatagridERLTM.dataContractualAmountsSqmByTenant"
                    key-expr="amountType"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                    :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />
                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="plain"
                      :caption="loc.TENANT.DATAGRIDINVOICEDCOL0CAPTION"
                      data-type="string"
                      alignment="left"
                      cell-template="diff-cell-template"
                      :allow-sorting="false"
                  />
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amount"
                      :caption="displayCurrentYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPY"
                      :caption="displayPreviousYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="amountPPY"
                      :caption="displayPPreviousYear"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="varSalesCYvsPY"-->
                  <!--                      :caption="displayPreviousYear"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataPercentage"-->
                  <!--                      cell-template="posNegCellTemplateSales"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="2"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="salesPY"-->
                  <!--                      :caption="displayPPreviousYear"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataDecimals"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="0"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="varSalesPYvsPPY"-->
                  <!--                      :caption="loc.TENANT.DATAGRIDNETSALESCOL4CAPTION"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataPercentage"-->
                  <!--                      cell-template="posNegCellTemplateSales"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="2"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="salesPPY"-->
                  <!--                      :caption="loc.FOOTFALL.DATAGRIDNETSALESCOL5CAPTION"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataDecimals"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="0"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->

                  <!-- template personalizzato celle datagrid -->
                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data"/>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                </DxDataGrid>
              </template>


            </basic-chart-datagrid-visualizer>
          </div>

        </template>
      </DxItem>

      <!-- ############## 5th row ############## -->
      <!--   CHART-DATAGRID   -->
      <DxItem>
        <DxLocation :row="5" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="4" :col="1" :colspan="2" screen="lg" />
        <template #default>
          <div style="position: relative;">

            <basic-chart-datagrid-visualizer
                :componentTitle="loc.TENANT.DENSITYVSPRODUCTCATEGORYTITLE"
                :refDate="null"
                :dataReady="pageDataReady"
                :switch-table-btn="loc.TENANT.CHARTDATAGRIDSWITCHTABLE"
                :switch-chart-btn="loc.TENANT.CHARTDATAGRIDSWITCHCHART"
            >

              <!-- ## LINE CHART -->
              <template v-slot:componentChart>
                <DxChart @drawn="onDrawn"
                         id="scatterChart-filtered-by-client"
                         :data-source="scatterDatagridERLTM.dataTenantCardDensityByTenant"
                         class="scatterChart"
                         :commonAxisSettings="scatterChartCommonAxisSettings"
                         :loadingIndicator="{enabled: true}"
                         @initialized="saveScatterErInstance0"
                >

                  <DxCommonSeriesSettings type="bar" />
           
                  <DxSeries argument-field="plain" value-field="tenant" :color="getOutletStyle.primaryColor" :name="loc.TENANT.DATAGRIDDENSITYVSPRODUCTCATEGORYl1CAPTION">
                    <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                  </DxSeries>
                  <DxSeries argument-field="plain" value-field="productCategory" :color="getOutletStyle.secondaryColor" :name="loc.TENANT.DATAGRIDDENSITYVSPRODUCTCATEGORYl2CAPTION">
                    <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />
                  </DxSeries>
                  
                  <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipCr.argumentFormat" :format="scatterERLTM.tooltipCr.valueFormat" />

                  <DxArgumentAxis :tick-interval="1">
                    <DxGrid :visible="false" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>

                  <DxValueAxis
                      :grid="{ visible: true }"
                      title=""
                      :visible="false"
                  />
                  <DxLegend
                      :visible="true"
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :margin="{ top: 25 }"
                      itemTextPosition="right"
                      :font="getLegendFontSettings"
                  />
                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplateFootfall="{ data }">
                    <div style="text-align: center">
                      Amount: € {{ data.valueText }}
                    </div>
                  </template>

                </DxChart>
              </template>

              <template v-slot:componentTab>
                <!-- ## DATAGRID -->
                <DxDataGrid
                    id="dataGrid-filtered-by-client"
                    class="datagrid"
                    :data-source="scatterDatagridERLTM.dataTenantCardDensityByTenant"                   
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    @exporting="onExportingDatagridEr"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-auto-width="true"
                    :column-hiding-enabled="true"
                    :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />
                  <DxColumn
                      css-class="cell-custom-style cell-body-head"
                      data-field="plain"
                      :caption="loc.TENANT.DATAGRIDDENSITYVSPRODUCTCATEGORYl0CAPTION"
                      data-type="string"
                      alignment="left"
                      cell-template="diff-cell-template"
                      :allow-sorting="false"
                  />
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="tenant"
                      :caption="loc.TENANT.DATAGRIDDENSITYVSPRODUCTCATEGORYl1CAPTION"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="productCategory"
                      :caption="loc.TENANT.DATAGRIDDENSITYVSPRODUCTCATEGORYl2CAPTION"
                      data-type="number"
                      alignment="left"
                      :allow-sorting="false"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <DxColumn
                      css-class="cell-custom-style"
                      data-field="variation"
                      :caption="loc.TENANT.DATAGRIDDENSITYVSPRODUCTCATEGORYl3CAPTION"             
                      :allow-sorting="false"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataPercentage"
                      cell-template="posNegCellTemplateSales"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="varSalesCYvsPY"-->
                  <!--                      :caption="displayPreviousYear"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataPercentage"-->
                  <!--                      cell-template="posNegCellTemplateSales"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="2"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="salesPY"-->
                  <!--                      :caption="displayPPreviousYear"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataDecimals"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="0"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="varSalesPYvsPPY"-->
                  <!--                      :caption="loc.TENANT.DATAGRIDNETSALESCOL4CAPTION"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataPercentage"-->
                  <!--                      cell-template="posNegCellTemplateSales"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="2"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->
                  <!--                  <DxColumn-->
                  <!--                      css-class="cell-custom-style"-->
                  <!--                      data-field="salesPPY"-->
                  <!--                      :caption="loc.FOOTFALL.DATAGRIDNETSALESCOL5CAPTION"-->
                  <!--                      data-type="number"-->
                  <!--                      alignment="left"-->
                  <!--                      :customizeText="customizeDatagridDataDecimals"-->
                  <!--                  >-->
                  <!--                    <DxFormat-->
                  <!--                        type="fixedPoint"-->
                  <!--                        :precision="0"-->
                  <!--                    />-->
                  <!--                  </DxColumn>-->

                  <!-- template personalizzato celle datagrid -->
                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data"/>
                  </template>

                  <template #ordinal-to-month="{ data }">
                    <div>{{ fromOrdinalToMonth(data.value) }}</div>
                  </template>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                </DxDataGrid>
              </template>


            </basic-chart-datagrid-visualizer>
          </div>

        </template>
      </DxItem>


      <!-- ############## 6th row ############## -->
      <!--   CHART-DATAGRID   -->
      <DxItem>
        <DxLocation :row="6" :col="0" :colspan="3" screen="xs sm md" />
        <DxLocation :row="5" :col="1" :colspan="2" screen="lg" />
        <template #default>
          <div style="position: relative;">

            <basic-chart-datagrid-visualizer
                :componentTitle="loc.TENANT.EFFORTRATETITLE"
                :refDate="null"
                :dataReady="pageDataReady"
                :switch-table-btn="loc.TENANT.CHARTDATAGRIDSWITCHTABLE"
                :switch-chart-btn="loc.TENANT.CHARTDATAGRIDSWITCHCHART"
            >

              <!-- ## LINE CHART -->
              <template v-slot:componentChart>
                <DxBarGauge
                    @drawn="onDrawn"
                    class="gauge"
                    :start-value="0"
                    :end-value="100"
                    :values="[scatterDatagridERLTM.dataERByTenant.erPPY, scatterDatagridERLTM.dataERByTenant.erPY, scatterDatagridERLTM.dataERByTenant.erLTM]"
                    :geometry="barGaugeGeometry"
                    :palette="[getOutletStyle.tertiaryColor, getOutletStyle.secondaryColor, getOutletStyle.primaryColor]"
                    resolveLabelOverlapping="hide"
                >
                  <DxLabel
                      :indent="30"
                      :format="format"
                      :customize-text="customizeText"
                      :font="barGaugeLabelFont"
                  />
                  <DxLegend
                      marker-template="customBarGaugeLegendTemplate"
                      :visible="true"
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      :customizeText="customizeTenantBarGaugeLegendText"
                      item-text-position="right"
                      :font="getLegendFontSettings"
                  />
                  <template #customBarGaugeLegendTemplate="{ data }">
                    <CustomBarGaugeLegendTemplate :item="data"/>
                  </template>

                </DxBarGauge>
              </template>

              <template v-slot:componentTab>
                <!-- ## DATAGRID -->
                <DxDataGrid
                    id="dataGrid-best-er"
                    class="datagrid"
                    :data-source="[scatterDatagridERLTM.dataERByTenant]"
                    :show-borders="false"
                    :loadPanel="{enabled: true}"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    :column-hiding-enabled="true"
                    :column-auto-width="true"
                    :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport
                      :enabled="true"
                  />
<!--                  <DxColumn-->
<!--                      :allow-sorting="false"-->
<!--                      data-field="tenant"-->
<!--                      :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL0CAPTION"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style cell-body-head"-->
<!--                      width="249"-->
<!--                      cell-template="diff-cell-template"-->

<!--                  />-->

                  <DxColumn
                      :allow-sorting="false"
                      data-field="erLTM"
                      :caption="loc.TENANT.DATAGRIDERCOL2CAPTION"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataPercentage"
                      css-class="cell-custom-style"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>

                  <DxColumn
                      :allow-sorting="false"
                      data-field="erPY"
                      :caption="displayPreviousYear"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataPercentage"
                      css-class="cell-custom-style"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>

                  <DxColumn
                      :allow-sorting="false"
                      data-field="erPPY"
                      :caption="displayPPreviousYear"
                      data-type="number"
                      alignment="left"
                      :customizeText="customizeDatagridDataPercentage"
                      css-class="cell-custom-style"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                  </DxColumn>

                  <!-- template personalizzato celle datagrid -->
                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data"/>
                  </template>

                  <template #posNegCellTemplateSales="{ data }">
                    <div :class="data.value != null ? data.value < 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                  <template #posNegCellTemplateEr="{ data }">
                    <div :class="data.value != null ? data.value > 0 ? 'negative-value' : '' : ''">{{ data.text}}</div>
                  </template>

                  <template #link-cell-template="{ data }">
                    <LinkCellTemplate :data="data" />
                  </template>

                </DxDataGrid>
              </template>


            </basic-chart-datagrid-visualizer>
          </div>

        </template>
      </DxItem>

      <!--   footer   -->
      <DxItem>
        <DxLocation :row="7" :col="2" :colspan="1" screen="lg" />
        <DxLocation :row="6" :col="2" :colspan="1" screen="xs sm md" />
        <template #default>
          <div class="app-version">
            <div>API: v. {{ getStoredAppVersion.versionNumber }} - {{ getStoredAppVersion.versionDate }}</div>
            <div>Client: v. 1.2.0 - 08/08/2024</div>
          </div>
        </template>
      </DxItem>

    </DxResponsiveBox>

  </div>
</template>

<script>
import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from "devextreme/localization";

import { loc } from "@/config/languages/en.js";

import DxSelectBox from 'devextreme-vue/select-box';
import 'devextreme/dist/css/dx.light.css';
import ApiCalls from "@/services/ApiCalls";

import { mapGetters } from "vuex";
import DxCalendar from 'devextreme-vue/calendar';

import {
  DxResponsiveBox,
  DxItem,
  DxLocation,
  DxCol,
  DxRow,
} from "devextreme-vue/responsive-box";

/******************************************************************************************************
 * # COMPONENTE - INIZIO
 **/
//
import { DxBarGauge } from 'devextreme-vue/bar-gauge';
//
import DxDataGrid, {
  // DxLoadPanel,
  DxPaging, DxColumn, DxFormat, DxExport } from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
//
import {
  DxChart,
  DxSeries,
  DxPoint,
  DxCommonSeriesSettings,
  DxLegend,
  DxValueAxis,
  DxArgumentAxis,
  DxGrid,
  DxMinorGrid,
  DxCommonPaneSettings,
  DxBorder,
  DxTooltip,

  DxZoomAndPan,

  // DxSize,
} from "devextreme-vue/chart";
//
import {
//   // DxFormat,
  DxLabel,
//   // DxConnector,
//   // DxExport
} from 'devextreme-vue/pie-chart';
//
import DxButton from 'devextreme-vue/button';
//
//
import DiffCell from '@/components/datagrid/DiffCell.vue';
import apiCalls from "../services/ApiCalls";

/**
 * # /COMPONENTE - FINE
 ******************************************************************************************************/


export default {
  components: {
    DxResponsiveBox,
    DxItem,
    DxLocation,
    DxCol,
    DxRow,
    DxSelectBox,
    DxButton,
    DxCalendar,

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/
    DxDataGrid,
    // DxLoadPanel,
    DxPaging,
    DxExport,


    DxChart,
    DxSeries,
    DxPoint,
    DxCommonSeriesSettings,
    DxLegend,
    DxValueAxis,
    DxArgumentAxis,
    DxGrid,
    DxMinorGrid,
    DxCommonPaneSettings,
    DxBorder,
    DxTooltip,
    DxZoomAndPan,
    // DxSize,
    DxColumn,
    DxFormat,


    // DxPieChart,
    // DxFormat,
    DxLabel,
    // // DxConnector,
    // // DxExport,
    //
    DxBarGauge,

    DiffCell, // componente per template personalizzato celle datagrid

    /**
     * # /COMPONENTE - FINE
     ******************************************************************************************************/
  },

  data() {
    /***************
     * # PROVVISORIO: ARRIVERà DA VUEX
     ***************/
        // let outlet = this.$store.getters.getSelectedOutlet.id ? this.$store.getters.getSelectedOutlet.id : 1; // #1
        // let refDate = {year: 2021, month: 10};
    // let selectedProductCategory = 0;
    // console.log("er outlet from store: ", outlet)

    return {
      requestedTenant: null,
      /***************
       * # PROVVISORIO: ARRIVERà DA VUEX
       ***************/
      // parametri per composizione chiamate - prov (arriveranno da vuex, impostato da subheader/partials/drop-down component)
      // outletId: outlet, /* #globale */
      // refDate: refDate, /* #globale */

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## DATE PER I COMPONENTI **********/
      refDateText: "", // arriva da chiamata refDate.plain
      comparisonDateText: "", // arriva da chiamata comparisonDate.plain

      // selectedProductCategory: selectedProductCategory, // #globale

      // /** ## SELETTORE CATEGORIE MERCEOLOGICHE **********/
      // categories: [], // todo: globale o componente? ogni quanto si ripopolerà? verrà preso da localstorage dopo prima chiamata?

      // DATAGRID CATEGORIES COMPONENT
      // selectedCategory: null, // forse non più utile... #check
      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

      /***************
       * # GENERALI E GLOBALI
       ***************/
      effortRateData: null, /************* # COMPONENTE *********************************/
      // dataFilteredByProductCategory: [], // #componente

      // flag per abilitare eventuali pulsanti solo quando i dati sono stati ricevuti
      pageDataReady: false, /************* # COMPONENTE *********************************/

      // flag per abilitare filtro categorie merceologiche (dipende da pagina - domain) #n
      productCategoryFilterDisabledByPage: null,

      // var per onOutletSelected e onTenantSelected
      defaultTenant: null,

      /** ## LOCALIZZAZIONE **********/
      locale: "it",

      loc: loc, // labels

      /** ## BREAKPOINTS **********/
      screenByWidth: function (width) {
        if (width < 768) return "xs";
        if (width < 1024) return "sm";
        if (width < 1150) return "md";
        // if (width < 1150) return "md";
        return "lg";
      },


      /** ## CALENDAR **********/
      calendarZoomLevel: "year", /* #componente: impostata di default, dipende da pagina: usata per cambiare stato active */
      maxSelectableDate: null,
      minSelectableDate: null, // #n
      calendarHidden: true,

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## BAR GAUGE **********/
      format: { /* #globale */
        type: 'fixedPoint',
        precision: 2
      },

      /** ## DATAGRID **********/
      customizeDatagridDataDecimals(value) { /* #globale */
        // console.log("custom data col: ", value)
        return value.valueText;
        // return value.valueText + " €";
      },

      customizeDatagridDataPercentage(value) { /* #globale */
        return value.valueText;
        // return value.valueText + " %";
      },

      // datagrid loader chiamata filtrata via api: ancora utile?
      loadingVisible: true, /* #componente */

      /***************
       * # COMPONENT ER LTM DISTRIBUTION - BAR GAUGE
       ***************/
      // todo: trasformare in oggetto ogni componente (solo var componente)?

      barGaugeER: { // #componente
        dataRef: [],
        dataComparison: [],
        legend: {},
      },

      barGaugeGeometry: { /* #globale */
        endAngle:-30,
        startAngle:210
      },
      colorGood: "#59C47F", /* #globale */
      colorNormal: "#FFD341", /* #globale */
      colorBad: "#FF5633", /* #globale */
      barGaugeLabelFont: {family: 'Nunito, sans-serif', size: 18, weight: 400}, /* #globale */
      // todo: label font-size a 15 in 768 e 1024

      /***************
       * # COMPONENT TEXT VISITORS
       ***************/
      textVisitors: {
        dataFilteredByProductCategory: {},
      },


      /***************
       * # COMPONENT ER LTM
       ***************/
      scatterDatagridERLTM: {
        // componentTitle: "Effort Rate LTM %",
        metadata: null,
        dataFilteredByProductCategory: null,
        dataOverviewFilteredByTenant: null, /* #componente */
        dataInvoicedAmountsByTenant: null, /* #componente */
        dataInvoicedAmountsSqmByTenant: null, /* #componente */
        dataContractualAmountsSqmByTenant: null, /* #componente */
        dataTenantCardDensityByTenant: null,
        dataERByTenant: null, /* #componente */
      },

      /** ## SCATTER PLOT **********/
      dataDisplayMode: 'chart', /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */

      effortRateDisplayMode: "chart", /* #componente */

      scatterErInstance0: null, /* #componente */
      scatterErInstance1: null, /* #componente */
      scatterErInstance2: null, /* #componente */

      scatterChartCommonAxisSettings: { /* #globale */
        //todo: color non viene applicato: scoprire cosa lo sovrascrive
        label:
            { font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#787878' }
            },
        title:
            { margin: 14 ,
              font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#666666' }
            }
      },

      scatterERLTM: {
        // argumentAxis: {
        //   title: { text: 'Net Sales (€)'},
        // },
        // valueAxis: {
        //   title: { text: 'E.R. (%)'},
        // },
        series1: {
          color: "#FF5633",
        },
        series2: {
          color: "#F8B5BF",
        },
        series3: {
          color: "#26B6C1",
        },
        points1: {
          color: "#FF7373",
          size: 15,
        },
        points2: {
          color: "#F8B5BF",
          size: 15,
        },
        points3: {
          color: "#26B6C1",
          size: 15,
        },
        tooltipVisitors: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'fixedPoint' },
        },
        tooltipCr: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'fixedPoint', precision: 2 },
        },
      },

      /***************
       * # COMPONENT WORST PERFORMER - DATAGRID
       ***************/
      datagridERWorstPerformer: {
        // componentTitle: "Worst Performers",
        dataFilteredByProductCategory: null,
      },

      /***************
       * # COMPONENT BEST PERFORMER - DATAGRID
       ***************/
      datagridERBestPerformer: {
        // componentTitle: "Best Performers",
        dataFilteredByProductCategory: null,
      },

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

    };
  },

  computed: {
    ...mapGetters(["getSelectedOutlet", "getOutletsList", "getTenantsList", "getOutletLastSalesDates", "getNewOutletLastSalesDates", "getOutletStyle", "getLegendFontSettings", "getStoredAppVersion"]), // #n

    displayCurrentYear() {
      // return new Date().getFullYear() + "";
      return /\w+\s(\d{4})/.exec(this.scatterDatagridERLTM.metadata.periods[0].plainAlt)[1] + "";

    },

    displayPreviousYear() {
      return this.scatterDatagridERLTM?.metadata?.yearComparison + "";
      // return "2020";
    },

    displayPPreviousYear() {
      return this.scatterDatagridERLTM?.metadata?.yearComparison2 + "";
      // return "2019";
    },

    /***************
     * # CALENDAR
     ***************/
    displayStringDate() { /* #globale */
      if(this.$store.state.selectedDate) {
        let year = this.$store.state.selectedDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.selectedDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      } else {
        let year = this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      }
    },
  },

  watch: {
    $route() {
      // visualizzazione route corretta se clicco su sidebar quando sono già su tenant-page
      if(this.$route.params.tenant == "all-tenants-page") {
        let newParameter = this.$store.state.selectedTenant ? this.$store.state.selectedTenant : this.defaultTenant;
        this.$router.push({ name: 'tenant-page', params: { tenant:  newParameter}});
      }
    },
  },

  methods: {
    changeDisplayData(value) { /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */
      this.dataDisplayMode = value;
    },

    getOutletsData() {

      // recupero vesione app
      apiCalls.getAppVersion()
          .then(res => {
            // window.localStorage.appVersion = JSON.stringify(res.data); // todo: sarebbe meglio salvarlo in vuex?
            // console.debug("app version: ", window.localStorage.appVersion);

            console.debug("prova version: ", res.data);

            this.$store.dispatch("dispatchAppVersion", res.data)

            // recupero timestamp last update
            apiCalls.getLastUpdateUTCTimestamp()
                .then(res => {
                  this.lastUpdateUTCTimestamp = res.data.lastUpdateUTCTimestamp;
                  console.debug("lastUpdateUTCTimestamp da chiamata: ", this.lastUpdateUTCTimestamp)

                  // check se local storage è aggiornato
                  this.isLocalExpired = window.localStorage.lastUpdateUTCTimestamp ? window.localStorage.lastUpdateUTCTimestamp != this.lastUpdateUTCTimestamp : true;
                  console.debug("isLocalExpired:", this.isLocalExpired)

                  // check se localstorage contiene già i dati necessari e se sono aggiornati
                  let isLocalOutletsList = window.localStorage.outletsList ? true : false; // controllo se voce nel local storage esiste
                  console.debug(`isLocalOutletsList:${isLocalOutletsList}; isLocalExpired:${this.isLocalExpired}`);

                  // i dati nel localStorage non esistono o sono troppo vecchi
                  if (isLocalOutletsList == false || this.isLocalExpired == true) {
                    this.isLocalStorageUsed = false; // #fn globale

                    /** ## outlets **********/
                    apiCalls.getOutlets()
                        .then(res => {
                          console.debug(`ApiCalls.getOutlets called`);
                          const DATA = res.data;

                          // salvo in vuex gli outlets
                          this.$store.dispatch("dispatchOutletsList", DATA)
                          // console.log("0.1. outlets list - store: ", this.$store.state);

                          // salvo nel window local storage gli outlets
                          if(!this.isLocalStorageUsed) {
                            this.setLocalStorageItem('outletsList', DATA)
                            this.setLocalStorageItemCreatedAt();
                            this.isLocalStorageUsed = true;
                          }

                          // imposto il selectedOutlet di default in vuex, se selectedOutlet non è già impostato
                          if(this.$store.state.selectedOutlet == null) {
                            let defaultOutlet = DATA.filter(outlet => outlet.id === 1)[0];
                            this.$store.dispatch("dispatchSelectedOutlet", defaultOutlet);
                            // console.log("(0.1.2). defaultOutlet: ", defaultOutlet);
                          }

                          /** ## date **********/
                              // prendo l'outlet selezionato da store e lo uso per ricavare e impostare in vuex le date delle ultime fatturazioni
                          let dates = DATA.filter(outlet => outlet.id === this.$store.state.selectedOutlet.id)[0] // old

                          // #n
                          console.log("dates nuovo: ", dates);

                          let lastAvailableSalesDates = [dates.lastAvailableMonthLTM, dates.lastAvailableMonthREF, dates.lastAvailableMonthYTD];
                          let firstAvailableSalesDates = [dates.firstAvailableMonthLTM, dates.firstAvailableMonthREF, dates.firstAvailableMonthYTD];

                          let lastAvailableSalesDate = Math.min(...lastAvailableSalesDates);
                          console.log("last sales date: ", lastAvailableSalesDate);
                          let firstAvailableSalesDate = Math.max(...firstAvailableSalesDates);
                          console.log("first sales date: ", firstAvailableSalesDate);

                          let newDates = {
                            ...dates,
                            firstAvailableSalesDate: firstAvailableSalesDate,
                            lastAvailableSalesDate: lastAvailableSalesDate,
                          }

                          this.$store.dispatch("dispatchNewOutletLastSalesDates", newDates)
                          console.log("new 0.2. outlet last sales dates - store: ", this.$store.state.newOutletLastSalesDates);
                          // /#n

                          this.$store.dispatch("dispatchOutletLastSalesDates", dates) // old
                          console.log("0.2. outlet last sales dates - store: ", this.$store.state.outletLastSalesDates);

                          // imposto ultima data selezionabile
                          this.setMaxSelectableDate();

                          /** ## tenants **********/
                          this.getTenantsByOutlet();
                        })
                  } else {
                    this.isLocalStorageUsed = true;
                    console.debug(`local storage data used`);

                    const DATA = this.retrieveLocalStorageItem('outletsList');

                    // salvo in vuex gli outlets
                    this.$store.dispatch("dispatchOutletsList", DATA)
                    // console.log("0.1. outlets list - store: ", this.$store.state);

                    // salvo nel window local storage gli outlets
                    if(!this.isLocalStorageUsed) {
                      this.setLocalStorageItem('outletsList', DATA)
                      this.setLocalStorageItemCreatedAt();
                    }

                    // imposto il selectedOutlet di default in vuex, se selectedOutlet non è già impostato
                    if(this.$store.state.selectedOutlet == null) {
                      let defaultOutlet = DATA.filter(outlet => outlet.id === 1)[0];
                      this.$store.dispatch("dispatchSelectedOutlet", defaultOutlet);
                      // console.log("(0.1.2). defaultOutlet: ", defaultOutlet);
                    }

                    /** ## date **********/
                        // prendo l'outlet selezionato da store e lo uso per ricavare e impostare in vuex le date delle ultime fatturazioni
                    let dates = DATA.filter(outlet => outlet.id === this.$store.state.selectedOutlet.id)[0] // old

                    // #n
                    console.log("dates nuovo: ", dates);

                    let lastAvailableSalesDates = [dates.lastAvailableMonthLTM, dates.lastAvailableMonthREF, dates.lastAvailableMonthYTD];
                    let firstAvailableSalesDates = [dates.firstAvailableMonthLTM, dates.firstAvailableMonthREF, dates.firstAvailableMonthYTD];

                    let lastAvailableSalesDate = Math.min(...lastAvailableSalesDates);
                    console.log("last sales date: ", lastAvailableSalesDate);
                    let firstAvailableSalesDate = Math.max(...firstAvailableSalesDates);
                    console.log("first sales date: ", firstAvailableSalesDate);

                    let newDates = {
                      ...dates,
                      firstAvailableSalesDate: firstAvailableSalesDate,
                      lastAvailableSalesDate: lastAvailableSalesDate,
                    }

                    this.$store.dispatch("dispatchNewOutletLastSalesDates", newDates)
                    console.log("new 0.2. outlet last sales dates - store: ", this.$store.state.newOutletLastSalesDates);
                    // /#n

                    this.$store.dispatch("dispatchOutletLastSalesDates", dates) // old
                    console.log("0.2. outlet last sales dates - store: ", this.$store.state.outletLastSalesDates);

                    // imposto ultima data selezionabile
                    this.setMaxSelectableDate();

                    /** ## tenants **********/
                    this.getTenantsByOutlet();
                  }

                })


          })


    },

    /***************
     * # CALENDAR
     ***************/

    // funzione per convertire numeri da una a due cifre
    convertFormatDate(date) {
      console.log("date: ", date);
      return String(date).length < 2 ? ("0" + date) : date;
    },

    onSelectedDate(e) {
      // console.log("selectedDate: ", new Date(e.value));
      let temp = new Date(e.value);
      // console.log("temp: ", temp);
      let selectedDate = `${temp.getFullYear()}${this.convertFormatDate(Number(temp.getMonth())+1)}${this.convertFormatDate(temp.getDate())}`
      // console.log("selectedDate: ", selectedDate);

      // imposto data selezionata in vuex
      this.$store.dispatch("dispatchSelectedDate", selectedDate);
      console.log("selectedDate - vuex: ", this.$store.state.selectedDate);

      // chiamata api per riprendere dati pagina er (tenendo conto di categoria selezionata)
      this.getEffortRateData();

      // nascondi il calendario
      this.calendarHidden = true;

    },

    // funzione per impostare il range di date selezionabili (month, week)
    onCalendarZoomLevelSelection(calendarZoomLevelSelected) {
      // impostazione zoom level calendario
      this.calendarZoomLevel = calendarZoomLevelSelected.value;
      console.log("calendar zoom level: ", this.calendarZoomLevel);

      // imposta ultima data selezionabile
      this.setMaxSelectableDate()
    },

    // imposta ultima data selezionabile
    setMaxSelectableDate() {
      if(this.calendarZoomLevel === "year") {
        // console.log("prova start date: ", this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate);
        let tempLastDateYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4);
        let tempLastDateMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempLastDate = new Date(tempLastDateYear, tempLastDateMonth, 0);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.maxSelectableDate = `${tempLastDate.getFullYear()}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n

        // console.log("calendar max selectable date: ", this.maxSelectableDate);
        // console.log("calendar max selectable date - old: ", this.$store.state.outletLastSalesDates.salesLastDayClosedMonth);  // old

        //  imposto min data selezionabile su calendario // #n
        console.log("prova start first date: ", this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate);
        let tempFirstDateYear = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(0, 4);
        let tempFirstDateMonth = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempFirstDate = new Date(tempFirstDateYear, tempFirstDateMonth, 1);
        console.log("temp first date: ", tempFirstDate);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.minSelectableDate = `${tempFirstDate.getFullYear()}${(tempFirstDate.getMonth()).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempFirstDate.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})}`; // #n

      }

      // if(this.calendarZoomLevel === "month") { // per ora non utilizzato !? attenzione: da aggiornare con lastAvailableSalesDate !? // #n
      //   this.maxSelectableDate = this.$store.state.outletLastSalesDates.salesLastDayClosedWeek;
      // }

      console.log("max selectable data: ", this.maxSelectableDate);
      console.log("calendar min selectable date: ", this.minSelectableDate);
    },

    onShowCalendar() {
      this.calendarHidden === true ? this.calendarHidden = false : this.calendarHidden = true;
    },


    // getCellCssClass(date) {
    //   let cssClass = '';
    //   const holydays = [[1, 0], [4, 6], [25, 11]];
    //
    //   if (this.isWeekend(date)) { cssClass = 'weekend'; }
    //
    //   holydays.forEach((item) => {
    //     if (date.getDate() === item[0] && date.getMonth() === item[1]) {
    //       cssClass = 'holyday';
    //     }
    //   });
    //
    //   return cssClass;
    // },

    /***************
     * # DATAGRID CHART
     ***************/
    /** ## CONVERTE GLI ORDINALI IN MESI (FOOTFALL, ...) **********/
    fromOrdinalToMonth(ordinalNumber) { /* #globale */
      if(typeof ordinalNumber != "number") {
        ordinalNumber = Number(ordinalNumber)
      }

      let output = ordinalNumber;
      switch(ordinalNumber) {
        case 1:
          output = "Jan";
          break;
        case 2:
          output = "Feb";
          break;
        case 3:
          output = "Mar";
          break;
        case 4:
          output = "Apr";
          break;
        case 5:
          output = "May";
          break;
        case 6:
          output = "Jun";
          break;
        case 7:
          output = "Jul";
          break;
        case 8:
          output = "Aug";
          break;
        case 9:
          output = "Sep";
          break;
        case 10:
          output = "Oct";
          break;
        case 11:
          output = "Nov";
          break;
        case 12:
          output = "Dec";
          break;
        case 100: // numero fittizio (cerca #tot in questa pagina)
          output = "TOT";
          break;
        case 200: // numero fittizio (cerca #tot in questa pagina)
          output = "TOT LTM";
          break;
        default:
          output = "-";
      }

      return output;
    },

    /** ## VALORE VISUALIZZATO IN ARGUMENT AXIS **********/
    newArgAxisValue(value) {
      return this.fromOrdinalToMonth(value.value);
    },

    /***************
     * # GENERALE
     ***************/
    onDrawn(e) { /* #globale */
      setTimeout(function () {
        e.component.render()
      }, 1)
    },

    // /** ## FILTRO OUTLETS - parte 2: definizione chiamate api necessarie **********/
    // getDataByCategories() {
    //   // recupero le categorie dell'outlet e le imposto nel vuex
    //   ApiCalls.getCategories(this.$store.state.selectedOutlet.id)
    //       .then(res => {
    //         // salvo in vuex le categorie
    //         this.$store.dispatch("dispatchCategoriesList", res.data)
    //         console.log("0.3 categories list - store: ", this.$store.state);
    //
    //         // salvo nel window local storage le categorie merceologiche
    //         window.localStorage.productCategories = res.data;
    //
    //         /***************
    //          * # CHIAMATA 1: locale, getters per popolare liste filtri (outlets, dates, categorie da vuex)
    //          ***************/
    //         // console.log("dates store getter: ", this.getOutletLastSalesDates);
    //
    //
    //         /***************
    //          * # CHIAMATA 2: api, fetch dati pagina er e valorizzazione data-source dei vari componenti -> #fn api in custom-file.js
    //          ***************/
    //         this.getEffortRateData();
    //
    //       })
    //       .catch(err => console.log(err.response));
    // },

    /** ## FILTRO OUTLETS - parte 2: definizione chiamate api necessarie **********/
    getTenantsByOutlet() {
      // recupero le categorie dell'outlet e le imposto nel vuex

      if(!window.localStorage[('tenantsListByOutlet' + this.$store.state.selectedOutlet.id)]) {
        ApiCalls.getTenants(this.$store.state.selectedOutlet.id)
            .then(res => {
              console.debug(`tenants api called`);
              const DATA = res.data;

              // salvo in vuex le categorie
              this.$store.dispatch("dispatchTenantsList", DATA)
              console.log("0.3 tenants list - store: ", this.$store.state);

              // salvo nel window local storage le categorie merceologiche
              // if(!this.isLocalStorageUsed) {
              this.setLocalStorageItem('tenantsListByOutlet' + this.$store.state.selectedOutlet.id, DATA)
              console.debug(`prod cat created ls: `, window.localStorage);
              // }

              /***************
               * # CHIAMATA 1: locale, getters per popolare liste filtri (outlets, dates, categorie da vuex)
               ***************/
              // console.log("dates store getter: ", this.getOutletLastSalesDates);


              /***************
               * # CHIAMATA 2: api, fetch dati pagina er e valorizzazione data-source dei vari componenti -> #fn api in custom-file.js
               ***************/
              // imposto il tenant di default (ogni volta che cambio outlet, tenant mostrato è il primo della lista
              this.defaultTenant = this.$store.state.tenantsList[0].id;

              // #qui: controllo da dove arrivo
              // se arrivo da sidebar, pulisco la barra degli indirizzi e procedo come da norma
              if (this.$route.params.tenant == "all-tenants-page") {
                this.$router.push({name: 'tenant-page', params: {tenant: this.defaultTenant}});
              }


              this.$store.dispatch("dispatchSelectedTenant", this.defaultTenant);
              // console.log("PROVA: ", this.$store.state.selectedTenant);

              if (this.$route.query && this.$route.query.isFromOutside) { // se arrivo da un link ad un tenant specifico
                this.requestedTenant = this.$route.params.tenant;
                this.$router.push({name: 'tenant-page', params: {tenant: this.$route.params.tenant}, query: {}})
                this.$store.dispatch("dispatchSelectedTenant", this.$route.params.tenant);
              }

              this.getEffortRateData();

            })
            .catch(err => console.log(err.response));
      } else {
        console.debug(`tenants loc storage called`);
        const DATA = this.retrieveLocalStorageItem('tenantsListByOutlet' + this.$store.state.selectedOutlet.id);

        // salvo in vuex le categorie
        this.$store.dispatch("dispatchTenantsList", DATA)
        console.log("0.3 tenants list - store: ", this.$store.state);

        /***************
         * # CHIAMATA 1: locale, getters per popolare liste filtri (outlets, dates, categorie da vuex)
         ***************/
        // console.log("dates store getter: ", this.getOutletLastSalesDates);


        /***************
         * # CHIAMATA 2: api, fetch dati pagina er e valorizzazione data-source dei vari componenti -> #fn api in custom-file.js
         ***************/
        // imposto il tenant di default (ogni volta che cambio outlet, tenant mostrato è il primo della lista
        this.defaultTenant = this.$store.state.tenantsList[0].id;

        // #qui: controllo da dove arrivo
        // se arrivo da sidebar, pulisco la barra degli indirizzi e procedo come da norma
        if (this.$route.params.tenant == "all-tenants-page") {
          this.$router.push({name: 'tenant-page', params: {tenant: this.defaultTenant}});
        }


        this.$store.dispatch("dispatchSelectedTenant", this.defaultTenant);
        // console.log("PROVA: ", this.$store.state.selectedTenant);

        if (this.$route.query && this.$route.query.isFromOutside) { // se arrivo da un link ad un tenant specifico
          this.requestedTenant = this.$route.params.tenant;
          this.$router.push({name: 'tenant-page', params: {tenant: this.$route.params.tenant}, query: {}})
          this.$store.dispatch("dispatchSelectedTenant", this.$route.params.tenant);
        }

        this.getEffortRateData();
      }
    },

    /** ## FILTRO OUTLETS - parte 1: impostazione filtro e chiamate api **********/
    onOutletSelected(e) {
      console.log("selezionato outlet: ", e.value);

      // condizione per pulsante clear: onclick passa null -> defaultOutlet
      let selectedOutletId = e.value == null ? 1 : e.value;

      // ricavo l'outlet dall'id selezionato (in vuex selectedOutlet è l'obj completo)
      let selectedOutlet = this.$store.state.outletsList.filter(outlet => outlet.id == selectedOutletId)[0];

      // invio l'outlet selezionato a vuex
      this.$store.dispatch("dispatchSelectedOutlet", selectedOutlet)

      console.log("selected outlet - vuex: ", this.$store.state.selectedOutlet);

      // al cambio di outlet, reimposto le categorie a 0 (all): non tutti gli outlets hanno le stesse categorie
      this.$store.dispatch("dispatchSelectedCategory", 0);
      // reimpostare anche selectbox categorie
      console.log("on outlet change, reset selected category - vuex: ", this.$store.state.selectedCategory);

      // // recupero le categorie dell'outlet e le imposto nel vuex
      // this.getDataByCategories();

      // recupero i tenants dell'outlet e li imposto nel vuex
      this.getTenantsByOutlet();

      // reimposto le ultime date selezionabili in calendario
      this.setMaxSelectableDate();
    },

    /** ## CHIAMATA API FILTRO OUTLETS (anche chiamata iniziale pagina) **********/
    getEffortRateData() {
      console.log("getEffortRateData called");

      // recupero variabili per chiamata da store
      let outletId = this.$store.state.selectedOutlet.id;
      // console.log("api call - outletId: ", outletId);

      let outletsDatesYear = "";
      let outletsDatesMonth = "";

      // controllo che selected date non sia impostata: se == null, cambiato selezione outlet o primo caricamento pagina: perciò prendo ultima data da outlet
      if(this.$store.state.selectedDate == null) {
        //#n
        outletsDatesYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4); // nel caso arrivi un numero, trasformo in stringa // #n
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10; // temp per test: non ci sono tutti i dati per l'ultimo mese fornito dalle api  // todo: ricordati di passare alla modalità non test
        outletsDatesMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6); // #n
        console.log("api call - outletsDatesMonth", outletsDatesMonth); // /#n
      } else {
        outletsDatesYear = this.$store.state.selectedDate.slice(0, 4);
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10;
        outletsDatesMonth = this.$store.state.selectedDate.slice(4, 6);
        console.log("api call - outletsDatesMonth", outletsDatesMonth);
      }

      // recupero tenant selezionato per comporre la chiamata
      let tenantId = this.requestedTenant == null ? this.$store.state.selectedTenant : this.requestedTenant;
      this.requestedTenant = null;

      ApiCalls.getTenantPage(outletId, tenantId) /* #componente */
          .then(res => {
            console.log("chiamata api er res: ", res.data);

            // // imposto route
            // this.$route.params.tenant = tenantId;

            // #n
            // imposta dominio in vuex per decidere quali filtri lasciare attivi
            let currentDomain = res.data.metadata.domain;
            this.$store.dispatch("dispatchCurrentDomain", currentDomain);
            // console.log("vuex domain: ", this.$store.state.currentDomain);

            // flag per disabilitare filtri a seconda della pagina
            if(this.$store.state.pagesDisabledFilters.productCategoryFilter.some(domain => domain == currentDomain)) {
              this.productCategoryFilterDisabledByPage = true;

              // se filtro disabilitato, visualizza tutte le categorie merceologiche
              this.$store.dispatch("dispatchSelectedCategory", 0);
            } else {
              this.productCategoryFilterDisabledByPage = false;
            }
            // console.log("productCategoryFilterDisabledByPage: ", this.productCategoryFilterDisabledByPage);
            // /#n

            // salvataggio dati pagina er
            this.effortRateData = res.data; /* #componente */ /************* # COMPONENTE *********************************/
            console.log("dati pagina:", this.effortRateData)

            // fn a parte (cambia per singolo componente): distribuisce dati ai vari componenti
            this.setComponentsData()
            // /fn a parte

          })
          .catch(err => console.log("error: ", err));
    },


    /** ## CHIAMATA LOCALE FILTRO CLIENT - CATEGORIE **********/
    onTenantSelected(e) {

      // imposto selectedCategory in vuex
      console.log("selezionato tenant: ", e.value);
      e.value == null ? ( // pulsante clear: se premuto, rimanda a default value
            // this.$store.dispatch("dispatchSelectedTenant", this.defaultTenant),
            // this.$router.push({ name: 'tenant-page', params: { tenant: this.defaultTenant }})
          console.log("tenant's clear button clicked")
          ) : (
            this.$store.dispatch("dispatchSelectedTenant", e.value),
            this.$router.push({ name: 'tenant-page', params: { tenant: e.value }})
          );
          console.log("on tenant selected - vuex: ", this.$store.state.selectedTenant);

      // console.log("selected tenant - vuex: ", this.$store.state.selectedTenant);

      // rifaccio la chiamata api e rivalorizzo i dataset dei vari componenti
      this.getEffortRateData();
    },

    /** ## VALORIZZAZIONE DATASET DEI VARI COMPONENTI **********/
    setComponentsData() {

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      let result = this.effortRateData.result; /************* # COMPONENTE *********************************/
      let metadata = this.effortRateData.metadata; /************* # COMPONENTE *********************************/
      let selectedTenant = this.$store.state.selectedTenant;

      /** ## DATA FOR LEGEND **********/
      this.scatterDatagridERLTM.metadata = metadata;

      /** ## DATA FOR ADVANCED TEXT COMPONENT **********/
      this.scatterDatagridERLTM.dataOverviewFilteredByTenant = result.tenantOverview;

      /** ## DATA FOR FIRST LINE CHART E DATAGRID **********/
      this.scatterDatagridERLTM.dataFilteredByProductCategoryDatagrid = [
          ...result.tenantCardSales.items,
        {
          ...result.tenantCardSales.totals, // #tot: nuovo dato inserito da Carmelo
          periodOrdinal: '100', // #tot: aggiungo valore fittizio perché manca attributo relativo a colonna nei dati forniti da Carmelo
        },
        {
          sales: result.tenantCardSales.totals.salesLTM,
          salesPY: result.tenantCardSales.totals.salesPY,
          salesPPY: result.tenantCardSales.totals.salesPPY,
          varSalesCYvsPY: result.tenantCardSales.totals.varSalesLTMvsPY,
          varSalesPYvsPPY: result.tenantCardSales.totals.varSalesPYvsPPY,     
          periodOrdinal: '200',
        }
      ];
      this.scatterDatagridERLTM.dataFilteredByProductCategory = result.tenantCardSales.items
      // console.log("scatterDatagridERLTM.dataFilteredByProductCategory all: ", this.scatterDatagridERLTM.dataFilteredByProductCategory);

       /** ## DATA FOR BAR CHARTS E DATAGRID **********/
       this.scatterDatagridERLTM.dataInvoicedAmountsByTenant = result.tenantCardContractualData.invoicedAmounts;
       this.scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant = result.tenantCardContractualData.invoicedAmountsSqm;

       console.log(this.scatterDatagridERLTM.dataInvoicedAmountsByTenant)

      for(let i=0; i< this.scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant.length; i++) {
        this.scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant[i]["amountTOT"] = this.scatterDatagridERLTM.dataInvoicedAmountsByTenant[i].amount;
        this.scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant[i]["amountPYTOT"] = this.scatterDatagridERLTM.dataInvoicedAmountsByTenant[i].amountPY;
        this.scatterDatagridERLTM.dataInvoicedAmountsSqmByTenant[i]["amountPPYTOT"] = this.scatterDatagridERLTM.dataInvoicedAmountsByTenant[i].amountPPY;
      }

       this.scatterDatagridERLTM.dataContractualAmountsSqmByTenant = result.tenantCardContractualData.contractualAmountsSqm;
       this.scatterDatagridERLTM.dataTenantCardDensityByTenant = [
        {
          plain: this.displayPPreviousYear,
          tenant: result.tenantCardDensity.densityTenantPPY,
          productCategory: result.tenantCardDensity.densityProdCatPPY,
          variation: result.tenantCardDensity.varDensityPPY,
        }, 
        {
          plain: this.displayPreviousYear,
          tenant: result.tenantCardDensity.densityTenantPY,
          productCategory: result.tenantCardDensity.densityProdCatPY,
          variation: result.tenantCardDensity.varDensityPY,
        }, 
        {
          plain: "LTM",
          tenant: result.tenantCardDensity.densityTenantLTM,
          productCategory: result.tenantCardDensity.densityProdCatLTM,
          variation: result.tenantCardDensity.varDensityLTM,
        }
      ]

      /** ## DATA FOR ER BAR GAUGE E DATAGRID **********/
      this.scatterDatagridERLTM.dataERByTenant = result.tenantCardEffortRate;

      // // proprietà che verrà usata per filtrare i tenants a seconda delle categorie di prodotto: se categoria negativa, fitrerò su food / non food, altrimenti su categoria prodotto
      // let targetProperty = selectedProductCategory < 0 ? "prodCatClusterId" : "prodCatId";
      //
      // // todo: prob, chiamata api salverà dati in variabile, poi chiamerà immediatamente filtro locale per distribuire i dati ai vari componenti; quando categoria prodotto viene chiamata, parte filtro locale; quando outlet o data vengono cambiatai parte chiamata api (che al suo interno ha chiamata a filtro locale)
      // /***** ### impostazione dati iniziali bar gauge *****/
      //
      // // todo: cambia con filte
      //
      // /** ## BAR GAUGE ER **********/
      //     // bar gauge ref's data (LTM)
      // let temp = {};
      // temp = result.effortRateClusteringLTM.filter(obj => obj[targetProperty] === selectedProductCategory)[0];
      // temp ? this.barGaugeER.dataRef = [temp.goodPercentage, temp.normalPercentage, temp.badPercentage] : [0, 0, 0];
      // // console.log("gauge legend ltm: ", this.barGaugeER.dataRef);
      //
      // // bar gauge comparison's data (LTMPY)
      // temp = result.effortRateClusteringLTMPY.filter(obj => obj[targetProperty] === selectedProductCategory)[0];
      // temp ? this.barGaugeER.dataComparison = [temp.goodPercentage, temp.normalPercentage, temp.badPercentage] : [0, 0, 0];
      // // // console.log("gauge legend ltmpy: ", this.barGaugeER.dataComparison);
      //
      //
      // // // bar gauge dates
      // metadata.periods[0] && metadata.periods[0].type === "REF" ?
      //     this.refDateText = metadata.periods[0].plain :
      //     this.refDateText = "not specified";
      //
      // metadata.periodsComparison[0] && metadata.periodsComparison[0].type === "REF" ?
      //     this.comparisonDateText = metadata.periodsComparison[0].plain :
      //     this.comparisonDateText = "not specified";
      //
      // // bar gauge legend
      // this.barGaugeER.legend = metadata.effortRateClusteringThresholds;
      //
      // /** ## DATA FOR TEXT VISITORS **********/
      // this.textVisitors.dataFilteredByProductCategory = result.footfallTotals.filter(el => el[targetProperty] === selectedProductCategory)[0];
      //
      // // dates
      // metadata.periods[0] && metadata.periods[0].type === "REF" ?
      //     this.refDateText = metadata.periods[0].plain :
      //     this.refDateText = "not specified";
      //
      // metadata.periodsComparison[0] && metadata.periodsComparison[0].type === "REF" ?
      //     this.comparisonDateText = metadata.periodsComparison[0].plain :
      //     this.comparisonDateText = "not specified";
      //
      // console.log("selectedProductCategory: ", selectedProductCategory);

      // /** ## DATA FOR SCATTER E DATAGRID **********/
      // /***** ### scatter-datagrid er ltm *****/
      // this.scatterDatagridERLTM.dataFilteredByProductCategory = selectedProductCategory === 0 ? result.effortRatesLTM : result.effortRatesLTM.filter(el => el[targetProperty] === selectedProductCategory);
      // console.log("scatterDatagridERLTM.dataFilteredByProductCategory all: ", this.scatterDatagridERLTM.dataFilteredByProductCategory);

      // /** ## DATA FOR LINE CHART E DATAGRID **********/
      // this.scatterDatagridERLTM.dataFilteredByProductCategory = result.footfallDetails.filter(el => el[targetProperty] === selectedProductCategory)[0].items;
      // console.log("scatterDatagridERLTM.dataFilteredByProductCategory all: ", this.scatterDatagridERLTM.dataFilteredByProductCategory);

      // /***** ### datagrid wp *****/
      // this.datagridERWorstPerformer.dataFilteredByProductCategory = result.worstEffortRatesLTM.filter(el => el[targetProperty] === selectedProductCategory)[0].items;
      // console.log("datagridERWorstPerformer.dataFilteredByProductCategory all: ", this.datagridERWorstPerformer.dataFilteredByProductCategory);
      //
      // /***** ### datagrid bp *****/
      // this.datagridERBestPerformer.dataFilteredByProductCategory = result.bestEffortRatesLTM.filter(el => el[targetProperty] === selectedProductCategory)[0].items;
      // console.log("datagridERBestPerformer.dataFilteredByProductCategory all: ", this.datagridERBestPerformer.dataFilteredByProductCategory);


      /***** ### flag per abilitare eventuali button o select *****/
      this.pageDataReady = true; /* #componente */

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

    },

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/

    /***************
     * # BAR GAUGE
     ***************/
    customizeText({valueText}) {
      return `${valueText} %`;
    },

    customizeTenantBarGaugeLegendText({text, item}) {
      if(item.index == 0) {
        return `${this.displayPPreviousYear} (${(text)}%)`
      }

      if(item.index == 1) {
        return `${this.displayPreviousYear} (${(text)}%)`
      }

      if(item.index == 2) {
        return `LTM (${(text)}%)`
      }
    },

    /***************
     * # DATAGRID
     ***************/
    /** ## EXPORT BUTTON **********/
    onExportingDatagridEr(e) { /* #globale */
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'DataGrid.xlsx');
            });
      });
      e.cancel = true;
    },

    /***************
     * # SCATTER
     ***************/
    /** ## RESET ZOOM BUTTON **********/
    saveScatterErInstance1(e) {
      this.scatterErInstance1 = e.component;
    },

    resetScatterErZoom0() {
      console.log(this.scatterErInstance0)
      this.scatterErInstance0.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    saveScatterErInstance0(e) {
      this.scatterErInstance0 = e.component;
    },

    resetScatterErZoom1() {
      console.log(this.scatterErInstance1)
      this.scatterErInstance1.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    saveScatterErInstance2(e) {
      this.scatterErInstance2 = e.component;
    },

    resetScatterErZoom2() {
      console.log(this.scatterErInstance2)
      this.scatterErInstance2.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    /** ## TOOLTIP **********/

    //  DATAGRID WORST EFFORT RATE
    //   sortDataWorstER(data) {
    //     return data.sort((a,b) => b.effortRatePercentLTM - a.effortRatePercentLTM);
    //   },
  },

  /**
   * # /COMPONENTE - FINE
   ******************************************************************************************************/

  beforeCreate() {
    // this.outletId = this.$store.getters.getSelectedOutlet.id; // #1
  },

  created() {
    // -- primo accesso all'app

    // CHIAMATA API MAIN.JS INIZIALE
    // 0. main.js fa le varie chiamate api per prendere le liste dei filtri (store - e data -, categorie) e li salva in vuex -> ho i dati per popolare i filtri
    //    (_ controllo localStorage per outlet)
    //    (_ salva dati in local storage)

    // -- primo accesso alla pagina
    // "CHIAMATA LOCALE" A VUEX PER PRENDERE I FILTRI ->
    // 1. prendo dati per popolare i filtri da vuex via getters: store, prodCatId/, dates -> popolo i filtri

    // CHIAMATA API PAGINA ER INIZIALE -> fn api
    // 2. prendo i filtri selezionati da vuex (default per primo accesso: prodCatId = 0, store = 1, data = data corrente (mese, ... dipende da pagina))
    // 3. faccio chiamata api con filtri selezionati (punto 2) per popolare i componenti;
    //    _ salvo i dati della chiamata api in una variabile per poterli usare con il filtro lato client
    //    _ popolo i data-set dei vari componenti con la variabile appena valorizzata

    // -- / fine primo accesso alla pagina

    // CHIAMATA LOCALE FILTRO CLIENT (CATEGORIE) -> fn client
    // 4. al click su filtro categorie, chiamo fn client
    // 5. fn:
    //    _ salva il filtro selezionato in vuex
    //    _ prendo i dati er salvati nella variabile locale, li filtro e li salvo nei dataset dei vari componenti

    // CHIAMATA API FILTRO DATA / OUTLET
    // 6. al click su filtro data / outlet,
    // 7. salvo filtro selezionato su vuex -> fn (una commit, action per variabile o una per tutte e 3?)
    // 8. chiamo fn api




    /***************
     * # LOCALIZZAZIONE
     ***************/
    loadMessages(itMessages);
    locale(this.locale);

    // #todo: adottare sintassi con params xé + chiara?

    // console.log("vuex: ", this.$store);

    /***************
     * # 0: main.js
     ***************/
    this.getOutletsData()

  },

};

// todo: chiedere perché componenti scoped prevengono alcune regole css (es: css-class in dxcolumn
</script>

<style lang="scss" scoped>
/***************
* # FILTRI
***************/
//
//.filterContainer { /* #globale */
//  padding: 10px;
//  display: flex;
//  justify-content: space-between;
//  flex-direction: row !important;
//  align-items: center;
//
//  //h2 {
//  //  font-family: Nunito, sans-serif;
//  //  font-size: 26px;
//  //  font-weight: 600;
//  //
//  //  @media screen and (max-width: 1024px){
//  //    font-size: 22px;
//  //  }
//  //}
//
//  //#categoriesListProv {
//  //  text-align: center;
//  //  padding: 7px;
//  //  border: none;
//  //  border-radius: 14px;
//  //  appearance: none;
//  //
//  //  color: #AA192F;
//  //  background-color: #FDE5E8;
//  //  //width: 117px;
//  //  //height: 24px;
//  //}
//
//}

/** ## CALENDAR **********/


/***************
* # BAR GAUGE
***************/
//.gauge {
//  height: 232px;
//}

/***************
* # SCATTER - DATAGRID
***************/
.scatterChart, .datagrid, .gauge {
  height: 413px;
}


/***************
* # COMPONENT WORST PERFORMER - DATAGRID
***************/

/*** ***** #!! ATTENZIONE: alcuni elementi css non vengono presi xé il componente è scoped
 -> passarli in custom-style.css !! */

//.tenant-column {
//  color: orange !important;
//  font-size: 50px !important;
//  background-color: green !important;
//}
//
//
//.cell-highlighted {
//  background-color: green !important;
//  color: orange !important;
//  font-style: italic !important;
//  font-size: 25px !important;
//}


// boh? #check
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
// /boh? #check


@media screen and (max-width: 1024px) {
  //.gauge { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
  //  height: 175px;
  //}

  .scatterChart, .datagrid, .gauge { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 313px;
  }
}
@media screen and (max-width: 768px) {


}
</style>
