<template>
  <div class="wrapper-paddings">
    <div class="componentWrapper">
      <h3 class="componentTitle">{{componentTitle}}</h3>

      <div class="comparisonDataContainer">
        <span class="comparisonDate date-style" v-if="comparisonDate">{{comparisonDate}}</span>
        <span class="comparisonValue" v-if="comparisonValue">
          <span v-if="isCurrency">€&nbsp;</span>{{comparisonValue.toLocaleString("it-IT")}}<span v-if="isPercentage">%</span>
        </span>
        <span class="comparisonValue" v-else>-</span>
      </div>

      <div class="midSectionContainer">
        <div class="refDate date-style" v-if="refDate">{{refDate}}</div>
        <div class="refValue" v-if="refValue"><span v-if="isCurrency">€&nbsp;</span>{{refValue.toLocaleString("it-IT")}}<span v-if="isPercentage">%</span></div>
        <div class="refValue" v-else>-</div>

        <div class="trendValue positive-value" v-if="trendValue != null && trendValue > 0">
          <span class="trendIcon positive-trend-indicator"><img src="../assets/images/trend_positive.png" alt=""></span>
          <span class="trendText">{{trendValue.toLocaleString("it-IT")}}%</span>
        </div>
        <div class="trendValue" v-else-if="trendValue != null && trendValue == 0">{{trendValue.toLocaleString("it-IT")}}%</div>
        <div class="trendValue negative-value" v-else-if="trendValue != null && trendValue < 0">
          <span class="trendIcon negative-trend-indicator"><img src="../assets/images/trend_negative.png" alt=""></span>
          <span class="trendText">{{trendValue.toLocaleString("it-IT")}}{{ isDeltaNumber ? '' : '%' }}</span>
        </div>
        <div class="trendValue" v-else-if="trendValue == null ">-</div>
        <div class="trendValue" v-else-if="emptyTrendValue">&nbsp;</div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  props:["componentTitle", "comparisonDate", "comparisonValue", "refDate", "refValue", "trendValue", "dataReady", "emptyTrendValue", "isCurrency", "isPercentage", "isDeltaNumber"],

}
</script>

<style lang="scss" scoped>

/***************
* # component
***************/

//420->531
.componentWrapper {
  //background-color: white;
  //border-radius: 11px;
  //border: none;
  //padding: 21px 32px;
  //margin: 10px;
  //height: 531px !important;

  //font-family: Nunito, sans-serif;

  //.componentTitle {
  //  font-weight: 600;
  //  font-size: 20px;
  //  line-height: 17px;
  //  color: #000;
  //  margin-top: 0;
  //  margin-bottom: 6px;
  //}

  .comparisonDataContainer {

    .comparisonValue {
      margin-left: 15px;
      font-weight: 600;
      font-size: 18px;
      color: #535353;
    }
  }

  .midSectionContainer {
    //display: flex;
    //justify-content: space-between;
    //margin-bottom: 24px;
    margin-top: 10px;
    text-align: center;


    .refDate {
      //font-weight: 600;
      //line-height: 19px;
      //font-size: 12px;
      //color: #a3a3a3;
      //text-align: center;
      //margin-bottom: 5px;
    }

    .refValue {
      margin-top: 5px;
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 1px;
      color: #535353;
    }

    .trendValue {
      //margin-top: 5px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;

      .trendIcon {
        font-size: 0.8em;
        margin-right: 7px;
        vertical-align: top;
      }

      .trendText {
        vertical-align: middle;
      }
    }

    .exportMenuContainer {
      //margin-left: 32px;
    }

  }

  .componentDataContainer > * {
    //outline: 1px solid lightblue;
    //height: 422px;
    overflow: auto; // #check
    //width: 100%;
  }


}
@media screen and (max-width: 1024px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      //margin-bottom: 24px;
      margin-top: 5px;

      .refDate {
        font-size: 12px;
      }

      .trendValue {
        font-weight: 400;
        font-size: 15px;
      }

    }
    //----
  }


  //.componentDataContainer > * {
  //  //outline: 1px solid lightblue;
  //  height: 312px;
  //  overflow: auto;
  //  //width: 100%;
  //}


}
@media screen and (max-width: 768px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      //margin-bottom: 24px;
      margin-top: 5px;

     .refValue {
       font-weight: 400;
       font-size: 25px;
     }



    }

  }
}
</style>
