<template>
  <div class="wrapper-paddings">
    <div class="componentWrapper">
      <h3 class="componentTitle">{{componentTitle}}</h3>
      <div class="midSectionContainer">
        <div class="refDate date-style" v-if="refDate">
          <span v-if="refDate">{{ refDate }}</span>
          <span v-if="comparisonDate">
            (<span class="date-comment" v-if="comparisonDateComment">{{ comparisonDateComment }}</span> {{comparisonDate}})
          </span>
        </div>
<!--        <div class="exportMenuContainer">-->
<!--          <slot name="componentExportMenu" class="exportMenu">...</slot>-->
<!--        </div>-->
      </div>

      <div class="componentTabContainer componentDataContainer">
        <slot name="componentTab" class="componentTab"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:["componentTitle", "refDate", "comparisonDate", "comparisonDateComment", "dataReady"],

}
</script>

<style lang="scss" scoped>

/***************
* # component
***************/

//420->531
.componentWrapper {
  //background-color: white;
  //border-radius: 11px;
  //border: none;
  //padding: 21px 32px;
  //margin: 10px;
  //height: 531px !important;

  //font-family: Nunito, sans-serif;

  //.componentTitle {
  //  font-weight: 600;
  //  font-size: 20px;
  //  line-height: 17px;
  //  color: #000;
  //  margin-top: 0;
  //  margin-bottom: 6px;
  //}

  .midSectionContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 10px;


    .refDate {
        //font-weight: 600;
        //line-height: 19px;
        //font-size: 12px;
        //color: #a3a3a3;
        //text-align: center;
        //margin-bottom: 5px;
      }

      .exportMenuContainer {
        //margin-left: 32px;
      }

  }
  //
  //.componentDataContainer {
  //  overflow-x: auto;
  //}

  .componentDataContainer > * {
    //outline: 1px solid lightblue;
    //height: 422px;
    overflow: auto; // #check
    //width: 100%;
  }


}
@media screen and (max-width: 1024px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      margin-bottom: 24px;
      margin-top: 5px;

    }
//----
  }


    //.componentDataContainer > * {
    //  //outline: 1px solid lightblue;
    //  height: 312px;
    //  overflow: auto;
    //  //width: 100%;
    //}


}
@media screen and (max-width: 768px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      margin-bottom: 24px;
      margin-top: 5px;

      .switchContainer {

        .switchItem {

          font-size: 12px;
        }

      }



    }

  }
}
</style>