export const loc = {
    COMMON : {
        RESETZOOMTEXT: "",
        NODATA: "No data to display"
    },

    LOGIN : {
        TITLE: "Log In",
        LOGINBUTTON: "Sign In",
        REQUIREDUSERNAMEMESSAGE: "Username is required",
        REQUIREDPASSWORDMESSAGE: "Password is required",
        BADCREDENTIALSMESSAGE: "Wrong username or password",
        UNKNOWNERRORMESSAGE: "Unknown error. Please, try again"
    },

    FILTERS : {
        PRODCATPLACEHOLDER: "Product Categories - All",
        CALENDARREFTITLE: `Current Selection`,
        CALENDARCOMPARISONTITLE: "Comparison Selection",
        TENANTSELECTPLACEHOLDER: "Search tenant...",
    },

    EFFORTRATE : {
        BARGAUGETITLE: "Effort Rate LTM Distribution: % Tenants in each ER cluster",
        BARGAUGELEGENDGOOD: "ER Good <= ",
        BARGAUGELEGENDNORMAL: " < ER Normal < ",
        BARGAUGELEGENDBAD: "ER Bad >= ",
        SCATTERDATAGRIDTITLE: "Effort Rate LTM",
        SCATTERARGAXISTITLE: "Net Sales (€)",
        SCATTERVALUEAXISTITLE: "Effort Rate (%)",
        CHARTDATAGRIDSWITCHTABLE: "Table",
        CHARTDATAGRIDSWITCHCHART: "Chart",
        DATAGRIDCOL1CAPTION: "Tenant",
        DATAGRIDCOL2CAPTION: "Product Category",
        DATAGRIDCOL3CAPTION: "Net Sales (€)",
        DATAGRIDCOL4CAPTION: "Effort Rate (%)",
        DATAGRIDWORSTTITLE: "Worst Performers LTM",
        DATAGRIDWORSTBESTREFDATECOMMENT: "PY = Previous Year: ",
        DATAGRIDWORSTBESTCOL0CAPTION: "Tenant",
        DATAGRIDWORSTBESTCOL1CAPTION: "Ranking",
        DATAGRIDWORSTBESTCOL2CAPTION: "Sales (€)",
        DATAGRIDWORSTBESTCOL3CAPTION: "Sales PY (€)",
        DATAGRIDWORSTBESTCOL4CAPTION: "Var %",
        DATAGRIDWORSTBESTCOL5CAPTION: "ER (%)",
        DATAGRIDWORSTBESTCOL6CAPTION: "ER PY (%)",
        DATAGRIDWORSTBESTCOL7CAPTION: "Δ (%)",

        DATAGRIDBESTTITLE: "Best Performers LTM",
    },

    FOOTFALL : {
        VISITORSTEXTTITLE: "Number of Visitors YTD",
        VISITORSTEXTTITLEREF: "Number of Visitors",
        CRTEXTTITLE: "Conversion Rate YTD",
        CRTEXTTITLEREF: "Conversion Rate",
        VISITORSTITLE: "Number of Visitors YTD",
        CRTITLE: "Conversion Rate YTD",
        LINECHARTARGAXISTITLE: "Month",
        LINECHART1VALUEAXISTITLE: "Visitors",
        LINECHART2VALUEAXISTITLE: "Conversion Rate (%)",
        CHARTDATAGRIDSWITCHTABLE: "Table",
        CHARTDATAGRIDSWITCHCHART: "Chart",
        CHARTDATAGRIDCOMMENT: "(PY = Previous Year)",
        DATAGRIDVISITORSCOL1CAPTION: "Months",
        DATAGRIDVISITORSCOL2CAPTION: "Visitors",
        DATAGRIDVISITORSCOL3CAPTION: "Visitors - PY",
        DATAGRIDVISITORSCOL4CAPTION: "Var %",

        DATAGRIDCRCOL1CAPTION: "Months",
        DATAGRIDCRCOL2CAPTION: "CR",
        DATAGRIDCRCOL3CAPTION: "CR - PY (%)",
        DATAGRIDCRCOL4CAPTION: "Δ %",
        DATAGRIDCRCOL41CAPTION: "Δ",

        CHARTVISITORSERIES1NAME: "Current year",
        CHARTVISITORSERIES2NAME: "Previous year",

    },

    MAINPANEL : {
        VISITORSTEXTTITLEREF: "Number of Visitors",
        VISITORSTEXTTITLELTM: "Number of Visitors LTM",
        CRTEXTTITLEREF: "Conversion Rate",
        CRTEXTTITLELTM: "Conversion Rate LTM",

        BARGAUGETITLE: "Effort Rate LTM Distribution: % Tenants in each ER cluster",
        VISITORSTEXTTITLE: "Number of Visitors YTD",
        CRTEXTTITLE: "Conversion Rate YTD",
        VISITORSTITLE: "Number of Visitors",
        CRTITLE: "CR Retail (%)",
        TENANTSTITLE: "Tenants",
        TENANTSDATAGRIDTAB0: "Opening Shops",
        TENANTSDATAGRIDTAB1: "Closing Shops",
        TENANTSDATAGRIDTAB2: "Indemnity Situation (*)",
        TENANTSDATAGRIDTAB3: "TBO Immediate Situation (**)",

        TENANTSTAB0COL0: "Tenant",
        TENANTSTAB0COL1: "Company",
        TENANTSTAB0COL2: "Open Date",
        TENANTSTAB0COL3: "Unit",
        TENANTSTAB0COL4: "GLA (sqm)",
        TENANTSTAB0COL5: "Product Category",

        TENANTSTAB1COL0: "Tenant",
        TENANTSTAB1COL1: "Company",
        TENANTSTAB1COL2: "Close Date",
        TENANTSTAB1COL3: "Unit",
        TENANTSTAB1COL4: "GLA (sqm)",
        TENANTSTAB1COL5: "Product Category",

        TENANTSTAB2COL0: "Tenant",
        TENANTSTAB2COL1: "Company",
        TENANTSTAB2COL2: "Contract Expiry Date",
        TENANTSTAB2COL3: "Unit",
        TENANTSTAB2COL4: "GLA (sqm)",
        TENANTSTAB2COL5: "Product Category",

        TENANTSTAB3COL0: "Tenant",
        TENANTSTAB3COL1: "Company",
        TENANTSTAB3COL2: "Contract Expiry Date",
        TENANTSTAB3COL3: "TBO Expiry Date",
        TENANTSTAB3COL4: "TBO Latest Notice Date",
        TENANTSTAB3COL5: "Rolling",
        TENANTSTAB3COL6: "Unit",
        TENANTSTAB3COL7: "GLA (sqm)",
        TENANTSTAB3COL8: "Product Category",

        TENANTSNOTE1PART1: "(**) Break Date by the end of ",
        TENANTSNOTE1PART2: " - TBO Rolling included",
        TENANTSNOTE2: "(*) Contract Expiry Date by the end of ",

    },

    TENANT : {
        // DATEUPDATEDTO: "Updated to",
        OVERVIEWSEC1TITLE: "Overview",
        OVERVIEWSEC2TITLE: "Contract",

        NETSALESTITLE: "Net Sales Trend Last Three Years",
        INVOICEDTITLE: "Invoiced Amounts (€)",
        INVOICEDSQMTITLE: "Invoiced Amounts (€/sqm)",
        INVOICEDDESCRIPTION: "Amounts related to issued invoices",
        RENTTITLE: "Contractual Rent and Additional Charges (€/sqm)",

        LINECHARTARGAXISTITLE: "Month",
        LINECHARTVALUEAXISTITLE: "Net Sales &nbsp; (€)",
        CHARTDATAGRIDSWITCHTABLE: "Table",
        CHARTDATAGRIDSWITCHCHART: "Chart",

        DATAGRIDNETSALESCOL0CAPTION: "Months",
        DATAGRIDNETSALESCOL1CAPTION: "Sales ",
        DATAGRIDNETSALESCOL2CAPTION: "- VS (%) -",
        DATAGRIDNETSALESCOL3CAPTION: "Sales ",
        DATAGRIDNETSALESCOL4CAPTION: "- VS (%) -",
        DATAGRIDNETSALESCOL5CAPTION: "Sales ",

        DATAGRIDINVOICEDCOL0CAPTION: "Amount type",

        EFFORTRATETITLE: "Effort Rate (%)",
        DATAGRIDERCOL2CAPTION: "LTM",

        DENSITYVSPRODUCTCATEGORYTITLE: "Tenant Density vs Product Category Density (€/sqm)",
        DATAGRIDDENSITYVSPRODUCTCATEGORYl0CAPTION: "Year",
        DATAGRIDDENSITYVSPRODUCTCATEGORYl1CAPTION: "Tenant Density",
        DATAGRIDDENSITYVSPRODUCTCATEGORYl2CAPTION: "Product Category Density",
        DATAGRIDDENSITYVSPRODUCTCATEGORYl3CAPTION: "Density Variation",
    },

    NETSALES : {
        NETSALES: "Net Sales",
        NETSALESYTD: "Net Sales YTD",
        NETSALESEUR: "Net Sales (€)",
        NETSALESSQM: "Net Sales/sqm",
        NETSALESSQMYTD: "Net Sales/sqm YTD",
        NETSALESSQMLTM: "Net Sales/sqm LTM",
        NETSALESSQMEUR: "Net Sales/sqm (€)",
        AVGTICKET: "Average Ticket",
        AVGTICKETLTM: "Average Ticket LTM",
        AVGTICKETYTD: "Average Ticket YTD",
        AVGTICKETEUR: "Average Ticket (€)",
        SPENDPERVISITOR: "Spend Per Visitor",
        SPENDPERVISITORYTD: "Spend Per Visitor YTD",
        SPENDPERVISITORLTM: "Spend Per Visitor LTM",
        SPENDPERVISITOREUR: "Spend Per Visitor (€)",
        OPENDAYS: "Open Days",
        CRRETAIL: "Conversion Rate",
        CRRETAILYTD: "Conversion Rate YTD",
        CRRETAILLTM: "Conversion Rate LTM",
        CHARTDATAGRIDSWITCHTABLE: "Table",
        CHARTDATAGRIDSWITCHCHART: "Chart",
        NETSALESNETSALESSQMLTM: "Net sales & Net Sales/sqm LTM",
        AVGTICKETSPENDPERVISITORLTM: "Average Ticket & Spend Per Visitor LTM",
        NETSALESLTM: "Net Sales LTM",
        CHARTVISITORSERIES1NAME: "Current year",
        CHARTVISITORSERIES2NAME: "Previous year",
        LINECHARTARGAXISTITLE: "Month",

        DATAGRIDVISITORSCOL1CAPTION: "Months",
        DATAGRIDVISITORSCOL2CAPTION: "Sales (€)",
        DATAGRIDVISITORSCOL3CAPTION: "Sales PY (€)",
        DATAGRIDVISITORSCOL4CAPTION: "Var %",
        DATAGRIDVISITORSCOL5CAPTION: "Sales/sqm (€)",
        DATAGRIDVISITORSCOL6CAPTION: "Sales/sqm PY (€)",
        DATAGRIDVISITORSCOL7CAPTION: "Var %",

        DATAGRIDVISITORSCOL8CAPTION: "Months",
        DATAGRIDVISITORSCOL9CAPTION: "AVGT (€)",
        DATAGRIDVISITORSCOL10CAPTION: "AVGT PY (€)",
        DATAGRIDVISITORSCOL11CAPTION: "Var %",
        DATAGRIDVISITORSCOL12CAPTION: "SPV (€)",
        DATAGRIDVISITORSCOL13CAPTION: "SPV PY (€)",
        DATAGRIDVISITORSCOL14CAPTION: "Var %",

        PY: "(PY = Previous Year:"
    },

    OCCUPANCY : {
        OCCUPANCY: "Occupancy",
        UNITS: "Units",
        LEASED: "N. of Leased Units",
        VACANT: "N. Vacant Unit",

        BARCHARTDATAGRIDLEASETITLE: "Lease expiry schedule: all Leases based on Break Date",
        CHARTLEASEVALUEAXIS1TITLE: "Potential Base Rent (€)",
        CHARTLEASEVALUEAXIS2TITLE: "Number of Leases",
        CHARTLEASEVALUEAXIS3TITLE: "GLA (sqm)",
        CHARTLEASEARGUMENTAXISTITLE: "Duration",
        DATAGRIDLEASEVACANTCOL0CAPTION: "Unit",
        DATAGRIDLEASEVACANTCOL1CAPTION: "GLA (sqm)",
        DATAGRIDLEASECOL0CAPTION: "Tenant",
        DATAGRIDLEASECOL1CAPTION: "Company",
        DATAGRIDLEASECOL2CAPTION: "Contract Expiry Date",
        DATAGRIDLEASECOL21CAPTION: "TBO Expiry Date",
        DATAGRIDLEASECOL22CAPTION: "TBO Latest Notice Date",
        DATAGRIDLEASECOL23CAPTION: "Rolling",
        DATAGRIDLEASECOL3CAPTION: "Unit",
        DATAGRIDLEASECOL4CAPTION: "GLA (sqm)",
        DATAGRIDLEASECOL5CAPTION: "Product Category",
        DATAGRIDLEASECOL6CAPTION: "Potential Rent (€)",

        BARCHARTDATAGRIDEXPIRYTITLE: "Contracts To Expiry",
        CHARTEXPIRYVALUEAXISTITLE: "Contracts",
        CHARTEXPIRYARGUMENTAXISTITLE: "Duration",
        DATAGRIDEXPIRYVACANTCOL0CAPTION: "Unit",
        DATAGRIDEXPIRYVACANTCOL1CAPTION: "GLA (sqm)",
        DATAGRIDEXPIRYCOL0CAPTION: "Tenant",
        DATAGRIDEXPIRYCOL1CAPTION: "Company",
        DATAGRIDEXPIRYCOL2CAPTION: "Contract Expiry Date",
        DATAGRIDEXPIRYCOL3CAPTION: "Unit",
        DATAGRIDEXPIRYCOL4CAPTION: "GLA (sqm)",
        DATAGRIDEXPIRYCOL5CAPTION: "Product Category",
        DATAGRIDEXPIRYCOL6CAPTION: "ER LTM (%)",

        BARCHARTDATAGRIDBREAKOPTTITLE: "Contracts To Break Option",
        CHARTBREAKOPTVALUEAXISTITLE: "Contracts",
        CHARTBREAKOPTARGUMENTAXISTITLE: "Duration",
        DATAGRIDBREAKOPTVACANTCOL0CAPTION: "Unit",
        DATAGRIDBREAKOPTVACANTCOL1CAPTION: "GLA (sqm)",
        DATAGRIDBREAKOPTCOL0CAPTION: "Tenant",
        DATAGRIDBREAKOPTCOL1CAPTION: "Company",
        DATAGRIDBREAKOPTCOL2CAPTION: "Contract Expiry Date",
        DATAGRIDBREAKOPTCOL21CAPTION: "TBO Expiry Date",
        DATAGRIDBREAKOPTCOL22CAPTION: "TBO Latest Notice Date",
        DATAGRIDBREAKOPTCOL23CAPTION: "Rolling",
        DATAGRIDBREAKOPTCOL3CAPTION: "Unit",
        DATAGRIDBREAKOPTCOL4CAPTION: "GLA (sqm)",
        DATAGRIDBREAKOPTCOL5CAPTION: "Product Category",
        DATAGRIDBREAKOPTCOL6CAPTION: "ER LTM (%)",

        DATAGRIDSUBTITLE: "Tenant list",

    },

    RENTSERVICES : {
        BARGAUGETITLE: "Base Rent/sqm LTM vs target ERV Distribution: % GLA in each MBR cluster ",
        BARGAUGELEGENDGOOD: "∆(%) Good > +",
        BARGAUGELEGENDNORMAL: " >= ∆(%) Normal >= ",
        BARGAUGELEGENDBAD: "∆(%) Bad < ",
        SCATTERDATAGRIDTITLE: "Base Rent/sqm LTM vs target ERV: Δ (%)",
        SCATTERARGAXISTITLE: "GLA (sqm)",
        SCATTERVALUEAXISTITLE: "MBR/sqm (€)",
        CHARTDATAGRIDSWITCHTABLE: "Table",
        CHARTDATAGRIDSWITCHCHART: "Chart",
        DATAGRIDCOL1CAPTION: "Tenant",
        DATAGRIDCOL2CAPTION: "Unit",
        DATAGRIDCOL3CAPTION: "GLA (sqm)",
        DATAGRIDCOL4CAPTION: "Opening date",
        DATAGRIDCOL5CAPTION: "Opening days",
        DATAGRIDCOL6CAPTION: "MBR/sqm (€)",
        DATAGRIDCOL61CAPTION: "ERV/sqm (€)",
        DATAGRIDCOL7CAPTION: "Δ (%)",
        DATAGRIDCOL71CAPTION: "Product Category",
        DATAGRIDGLACATTITLE: "MBR, MBR/sqm, SVC, SVC/sqm LTM per GLA Category",
        DATAGRIDTENANTTITLE: "MBR, MBR/sqm, SVC, SVC/sqm LTM per Tenant",
        DATAGRIDWORSTBESTREFDATECOMMENT: "PY = Previous Year: ",
        DATAGRIDGLACATTENANTCOL0CAPTION: "GLA Range (sqm)",
        DATAGRIDGLACATTENANTCOL01CAPTION: "Tenant",
        DATAGRIDGLACATTENANTCOL02CAPTION: "Product Category",
        DATAGRIDGLACATTENANTCOL1CAPTION: "GLA (sqm)",
        DATAGRIDGLACATTENANTCOL2CAPTION: "MBR (€)",
        DATAGRIDGLACATTENANTCOL3CAPTION: "MBR/sqm (€)",
        DATAGRIDGLACATTENANTCOL4CAPTION: "SVC (€)",
        DATAGRIDGLACATTENANTCOL5CAPTION: "SVC/sqm (€)",
        // DATAGRIDGLACATTENANTCOL6CAPTION: "ER PY (%)",
        // DATAGRIDGLACATTENANTCOL7CAPTION: "Δ (%)",

        DATAGRIDBESTTITLE: "Best Performers LTM",
    },
}
