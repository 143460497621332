<template>
  <div :class="this.showCellDate == this.showTargetDate ? 'custom-calendar-selected-date' : ''">
    {{ isMonth(data) }}
  </div>
</template>
<script>

export default {
  props: ["data", "item"],

  methods: {
    // controlla se viene passato un mese o un anno (senza non funzionerebbe correttamente a causa della fn tolocalestring)
    isMonth(value) {
      return /[\d+-]/.test(value.text) ?
      value.text :
      value.date.toLocaleString("en-us", { month: 'short' });
    }
  },

  computed: {
    // data selezionata dall'utente o ricavata da limite massimo outlet (se nessuna data è stata selezionata)
    showTargetDate() {
      return this.$store.state.selectedDate != null ? `${('' + this.$store.state.selectedDate).slice(0,4)}-${('' + this.$store.state.selectedDate).slice(4,6)}` : `${('' + this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)}-${('' + this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)}`;
    },
    showCellDate() {
      return this.data.date.getFullYear() +'-'+(this.data.date.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})
    },

  },

};
</script>
<style lang="scss" scoped>

.custom-calendar-selected-date {
  background-color: #AA192F !important;
  color: white !important;
  border-radius: 4px !important;
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
}

</style>
