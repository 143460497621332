<template>
    <div class="base-rent" style="">
        <span class="value" :style="ervCat == 'GOOD' ? `background-color: ${colors.colorGood}; color: white` : ervCat == 'NORMAL' ? `background-color: ${colors.colorNormal}` : ervCat == 'BAD' ? `background-color: ${colors.colorBad}; color: white` : ervCat == 'NO_ERV' ? `background-color: ${colors.colorVacant}; color: white` : ''">
          {{ cellData.text }}
        </span>
    </div>
</template>
<script>
// import {
//   DxSparkline,
//   DxSize,
//   DxTooltip,
// } from 'devextreme-vue/sparkline';


// import mapGetters from "vuex";

export default {
  // components: {
  //   DxSparkline,
  //   DxSize,
  //   DxTooltip,
  // },
  props: {
    cellData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      ervCat: this.cellData.data.varRentSqmCat,
      colors: this.$store.state.legendColors,
    }
  },

};
</script>
<style lang="scss" scoped>
a:hover {
  text-decoration: none !important;
}

.base-rent {
  .value {
    padding: 2px 8px;
    border: none;
    border-radius: 14px;
  }
}

</style>
