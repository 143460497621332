const ffYears = [
    { value: 'ff2016', name: 'FF 2016' },
    { value: 'ff2017', name: 'FF 2017' },
    { value: 'ff2018', name: 'FF 2018' },
    
    // { value: 'coal', name: 'Coal' },
    // { value: 'nuclear', name: 'Nuclear' }
  ];
  
  const monthsInfo = [
  {
    month: '1',
    ff2016: 15000,
    ff2017: 5000,
    ff2018: 6000,
    // coal: 564.3,
    // nuclear: 187.9
  }, {
    month: '2',
    ff2016: 20000,
    ff2017: 7000,
    ff2018: 6400,
    // coal: 956.9,
    // nuclear: 11.3
  }, {
    month: '3',
    ff2016: 10000,
    ff2017: 10000,
    ff2018: 6900,
    // coal: 105,
    // nuclear: 32.4
  }, {
    month: '4',
    ff2016: 20000,
    ff2017: 17000,
    ff2018: 30000,
    // coal: 120.8,
    // nuclear: 64.8
  }, {
    month: '5',
    ff2016: 5000,
    ff2017: 6000,
    ff2018: 9000,
  //   coal: 204.8,
  //   nuclear: 3.8
  }, {
    month: '6',
    ff2016: 3500,
    ff2017: 4000,
    ff2018: 5500,
    // coal: 85.7,
    // nuclear: 37.8
  }, 
  {
    month: '7',
    ff2016: 10000,
    ff2017: 10500,
    ff2018: 25000,
    // coal: 85.7,
    // nuclear: 37.8
  },
  {
    month: '8',
    ff2016: 35000,
    ff2017: 30000,
    ff2018: 22000,
    // coal: 85.7,
    // nuclear: 37.8
  },
  {
    month: '9',
    ff2016: 32000,
    ff2017: 26000,
    ff2018: 19000,
    // coal: 85.7,
    // nuclear: 37.8
  },
  {
    month: '10',
    ff2016: 32000,
    ff2017: 26000,
    ff2018: 19000,
    // coal: 85.7,
    // nuclear: 37.8
  },
];
  
  export default {
    getFFYears() {
      return ffYears;
    },
    getMonthsInfo() {
      return monthsInfo;
    }
  };
  