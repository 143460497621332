<template>
    <div class="wrapper">
        <h3 class="fieldTitle">{{ fieldTitle }}</h3>
        <div class="comparisonElements"><span class="comparisonDate">{{comparisonDate}}</span> <span class="comparisonValue">{{comparisonValue}}</span></div>
        <div class="currentDate">{{currentDate}}</div>
        <div class="currentValue">{{currentValue}}</div>
        <div :class="/\+/.test(this.gain) ? 'gain-positive' : 'gain-negative'">{{gain}}</div>
    </div>
</template>

<script>
    export default {
      props: ["fieldTitle", "comparisonDate", "comparisonValue", "currentDate", "currentValue", "gain"],
        data() {
            return {

            }
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 100%;
        height: 211px;
        border-radius: 11px;
        font-family: 'Nunito', sans-serif;
        background-color: white;
        padding: 21px 32px;
        margin: 10px;

        .fieldTitle {
            font-weight: 600;
            font-size: 20px;
            line-height: 17px;
            color: #000;
            margin-top: 0;
            margin-bottom: 6px;
        }

        .comparisonElements {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 23px;

            .comparisonDate {
                color: #a3a3a3;
            }

            .comparisonValue {
                color: #535353;
            }
        }

        .currentDate {
            font-weight: 600;
            line-height: 19px;
            font-size: 14px;
            color: #a3a3a3;
            text-align: center;
        }

        .currentValue {
            font-weight: 400;
            font-size: 35px;
            line-height: 54px;
            text-align: center;
        }

        .gain-positive, .gain-negative {
            color: #16810d;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }

        .gain-positive {
            color: #16810d;
        }

        .gain-negative {
            color: #FF5633;
        }
    }
    @media screen and (max-width: 1024px) {
      .wrapper{
        //height: 420px !important;

        .fieldTitle {
          font-weight: 400;
          font-size: 15px;
          line-height: 17px;
          color: #000;
          margin-top: 0;
          margin-bottom: 6px;
        }


        .comparisonDate, .dataDate {
          font-weight: 600;
          line-height: 19px;
          font-size: 12px;
          color: #a3a3a3;
          text-align: center;
          margin-bottom: 5px;
        }

        .componentDoughnut {

        }

        .currentDate {
          font-weight: 600;
          line-height: 19px;
          font-size: 12px;
          color: #a3a3a3;
          text-align: center;
        }

        .currentValue {
          font-weight: 400;
          font-size: 30px;
          line-height: 54px;
          text-align: center;
        }

        .gain-positive, .gain-negative {
          color: #16810d;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
        }




      }
    }
    @media screen and (max-width: 768px) {
      .componentWrapper {
        //height: 300px !important;
      }
    }
</style>