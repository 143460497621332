<template>
  <svg>
    <!-- <circle
      :r="pieChart.getInnerRadius() - 6"
      cx="100"
      cy="100"
      fill="transparent"
    /> -->
    <!-- <image
      :href="getImagePath(country)"
      x="70"
      y="58"
      width="60"
      height="40"
    /> -->
    <text
      :style="{fontFamily: 'Nunito' , fontWeight: 600, fill:&quot;#494949&quot;}"
      text-anchor="middle"
      x="100"
      y="76"
    >
      <tspan x="100"
        :style="{ fontSize: 18 }"
      >{{ data1 }}</tspan>
      <tspan
        :style="{ fontSize: 14, fill: 'green' }"
        x="100"
        dy="18px"
      >{{ data2 }}</tspan>
    </text>
  </svg>
</template>
<script>

// const formatNumber = new Intl.NumberFormat('en-US', {
//   minimumFractionDigits: 0
// }).format;

export default {
  props: {
    pieChart: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data1: "43%",
      data2: "+2%",
      // country: this.pieChart.getAllSeries()[0].getVisiblePoints()[0].data.country
    };
  },
  methods: {
    /* getImagePath(country) {
      return `images/flags/${ country.replace(/\s/, '').toLowerCase() }.svg`;
    },

    calculateTotal(pieChart) {
      return formatNumber(pieChart.getAllSeries()[0].getVisiblePoints().reduce((s, p) => s + p.originalValue, 0));
    } */
  }
};
</script>