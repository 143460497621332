<template>
  <div style="height: 100%; width: 30px">
    <router-link v-if="tenantData.tenant != null" style="display: inline-block; height: 100%; width:100%; position:relative" :to="{ name: 'tenant-page', params: { tenant: data.value }, query: { isFromOutside: true } }">
        <span style="position: absolute; bottom: 9px">
          <img src="@/assets/images/file_img.png" alt="">
        </span>
    </router-link>
    <span v-else style="padding-left: 5px">

      </span>
  </div>
</template>
<script>

export default {

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tenantData: this.data.data,
    }
  },
  mounted() {
    console.log("prova data", this.data)
  }

};
</script>
<style lang="scss" scoped>


</style>
