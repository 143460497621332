<template>
    <div class="container custom-wrapper px-0">
<!--        <drop-menu></drop-menu>-->
<!--        <calendar-link></calendar-link>-->

    </div>
</template>

<script>
// import CalendarLink from './partials/calendar-link.vue';
// import dropMenu from './partials/drop-menu.vue';
// import Calendar from 'devextreme/ui/calendar';
    export default {
  // components: { dropMenu, CalendarLink },
        
    }
</script>

<style lang="scss" scoped>
    .custom-wrapper {
        //height: 86px;
        //padding-top: 35px;
        //display: flex;
        //justify-content: space-between;
    }
</style>