<template>
  <div class="wrapper-paddings">
    <div class="componentWrapper">
      <h3 class="componentTitle advanced-text-component">{{ displayCorrectFormat({value: data.tenant}) }}</h3>

      <section class="section-container first-section">
        <h4 class="section-title">{{sectionOneTitle}}</h4>

        <div class="section-content">
          <div class="info-block" style="grid-area: company">
            <div class="info-block-title">Company</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.company}) }}</div>
          </div>


          <div class="info-block" style="grid-area: productCategory">
            <div class="info-block-title">Product Category</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.productCategory}) }}</div>
          </div>

          <div class="info-block" style="grid-area: openDate">
            <div class="info-block-title">Open Date</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.openDate, isDate: true}) }}</div>
          </div>

          <div class="info-block" style="grid-area: unit">
            <div class="info-block-title">Unit</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.unit}) }}</div>
          </div>

          <div class="info-block" style="grid-area: brandPosition">
            <div class="info-block-title">Brand Position</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.brandPosition}) }}</div>
          </div>

          <div class="info-block" style="grid-area: closeDate">
            <div class="info-block-title">Close Date</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.closeDate, isDate: true}) }}</div>
          </div>

          <div class="info-block" style="grid-area: gla">
            <div class="info-block-title">GLA (sqm)</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.gla, isNumber: true}) }}</div>
          </div>

          <div class="info-block" style="grid-area: salesArea">
            <div class="info-block-title">Sales Area (sqm)</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.salesArea, isNumber: true}) }}</div>
          </div>
        </div>
      </section>

      <section class="section-container second-section">
        <h4 class="section-title">{{sectionTwoTitle}}</h4>
        <div class="section-content">

          <div class="info-block" style="grid-area: contractStartDate">
            <div class="info-block-title">Start Date</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.contractStartDate, isDate: true}) }}</div>
          </div>

          <div class="info-block" style="grid-area: variableContractualConditions">
            <div class="info-block-title">Var. Contractual Cond.</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.variableContractualConditions}) }}</div>
          </div>

          <div class="info-block" style="grid-area: tboExpiryDate">
            <div class="info-block-title">TBO Expiry Dates</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.tboExpiryDates}).split(" ").join("\r\n") }}</div>
          </div>
          <div class="info-block" style="grid-area: lboExpiryDate">
            <div class="info-block-title">LBO Expiry Date</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.lboExpiryDate, isDate: true}) }}</div>
          </div>
          <div class="info-block" style="grid-area: contractExpiryDate">
            <div class="info-block-title">Expiry Date</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.contractExpiryDate, isDate: true}) }}</div>
          </div>
          <div class="info-block" style="grid-area: ratchetPercent">
            <div class="info-block-title">RATCHET Percentage</div>
            <div class="info-block-value">{{ displayCorrectFormat({value: data.ratchetPercent, isNumber: true}) }} <span v-if="data.ratchetPercent">%</span></div>
          </div>
          <div class="info-block" style="grid-area: tboSalesThreshold">
            <div class="info-block-title">Sales/sqm Thresh. TBO</div>
            <div class="info-block-value"><span v-if="data && data.tboSalesThreshold">€</span> {{ displayCorrectFormat({value: data.tboSalesThreshold, isNumber: true}) }}</div>
          </div>
          <div class="info-block" style="grid-area: lboSalesThreshold">
            <div class="info-block-title">Sales/sqm Thresh. LBO</div>
            <div class="info-block-value"><span v-if="data && data.lboSalesThreshold">€</span> {{ displayCorrectFormat({value: data.lboSalesThreshold, isNumber: true}) }}</div>
          </div>
        </div>
      </section>








<!--      <div class="comparisonDataContainer">-->
<!--        <span class="comparisonDate date-style" v-if="comparisonDate">{{comparisonDate}}</span>-->
<!--        <span class="comparisonValue" v-if="comparisonValue">{{comparisonValue.toLocaleString("it-IT")}}</span>-->
<!--      </div>-->

<!--      <div class="midSectionContainer">-->
<!--        <div class="refDate date-style" v-if="refDate">{{refDate}}</div>-->
<!--        <div class="refValue" v-if="refValue">{{refValue.toLocaleString("it-IT")}}</div>-->

<!--        <div class="trendValue positive-value" v-if="trendValue != null && trendValue > 0"><span class="trendIcon">&nearr;</span>{{trendValue.toLocaleString("it-IT")}}%</div>-->
<!--        <div class="trendValue" v-else-if="trendValue != null && trendValue == 0">{{trendValue.toLocaleString("it-IT")}}%</div>-->
<!--        <div class="trendValue negative-value" v-else-if="trendValue != null && trendValue < 0"><span class="trendIcon">&searr;</span>{{trendValue.toLocaleString("it-IT")}}%</div>-->

<!--      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  props:["componentTitle", "sectionOneTitle", "sectionTwoTitle", "data"],
  methods: {
    displayCorrectFormat({value, isNumber = false, isDate = false}) {
      // è null?
      if(value == null) {
        return "-";
      }

      // è un numero?
      if(isNumber) {
        return value.toLocaleString("it-IT");
      }
      
      // è una data?
      if(isDate) {
        return `${value.slice(6,8)}/${value.slice(4,6)}/${value.slice(0,4)}`;
      }

      return value;
    },
  },

}
</script>

<style lang="scss" scoped>
// da sistemare nested
.section-container {
  padding-top: 30px;
}

.section-content {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 5px;

    .info-block {
      padding-bottom: 10px;

      .info-block-title {
        padding-bottom: 1px;
      }
    }
}

.first-section {
  .section-content {
    grid-template-rows: repeat(5, auto);
    grid-template-areas:
    "company ."
    "productCategory brandPosition"
    "openDate closeDate"
    "unit gla"
    "salesArea ."
  ;
  }
}

.second-section {
  .section-content {
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
    "contractStartDate contractExpiryDate"
    "variableContractualConditions ratchetPercent"
    "tboExpiryDate tboSalesThreshold"
    "lboExpiryDate lboSalesThreshold"
  ;
  }
}

.componentTitle.advanced-text-component {
  text-align: center;
}

.section-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.info-block {
  .info-block-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #787878;
  }
  .info-block-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }


}
// /


/***************
* # component
***************/

//420->531
.componentWrapper {
  //background-color: white;
  //border-radius: 11px;
  //border: none;
  //padding: 21px 32px;
  //margin: 10px;
  //height: 531px !important;

  //font-family: Nunito, sans-serif;

  //.componentTitle {
  //  font-weight: 600;
  //  font-size: 20px;
  //  line-height: 17px;
  //  color: #000;
  //  margin-top: 0;
  //  margin-bottom: 6px;
  //}

  .comparisonDataContainer {

    .comparisonValue {
      margin-left: 10px;
      font-weight: 600;
    }
  }

  .midSectionContainer {
    //display: flex;
    //justify-content: space-between;
    //margin-bottom: 24px;
    margin-top: 10px;
    text-align: center;


    .refDate {
      //font-weight: 600;
      //line-height: 19px;
      //font-size: 12px;
      //color: #a3a3a3;
      //text-align: center;
      //margin-bottom: 5px;
    }

    .refValue {
      margin-top: 5px;
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 1px;
    }

    .trendValue {
      //margin-top: 5px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;

      .trendIcon {
        font-size: 0.8em;
        margin-right: 7px;
        vertical-align: top;
      }
    }

    .exportMenuContainer {
      //margin-left: 32px;
    }

  }

  .componentDataContainer > * {
    //outline: 1px solid lightblue;
    //height: 422px;
    overflow: auto; // #check
    //width: 100%;
  }


}

@media screen and (max-width: 1149px) {

  .section-content {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: repeat(2, auto);
  }

  .first-section {
    .section-content {
      grid-template-areas:
    "company productCategory brandPosition . ."
    "openDate closeDate unit gla salesArea"
    ;
    }
  }

  .second-section {
    .section-content {
      grid-template-areas:
    "contractStartDate contractExpiryDate tboExpiryDate tboSalesThreshold"
    "variableContractualConditions ratchetPercent lboExpiryDate lboSalesThreshold"
    ;
    }
  }

}

@media screen and (max-width: 1024px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      //margin-bottom: 24px;
      margin-top: 5px;

      .refDate {
        font-size: 12px;
      }

      .trendValue {
        font-weight: 400;
        font-size: 15px;
      }

    }
    //----
  }


  //.componentDataContainer > * {
  //  //outline: 1px solid lightblue;
  //  height: 312px;
  //  overflow: auto;
  //  //width: 100%;
  //}


}
@media screen and (max-width: 768px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      //margin-bottom: 24px;
      margin-top: 5px;

      .refValue {
        font-weight: 400;
        font-size: 25px;
      }



    }

  }
}
</style>