<template>
  <div class="componentContainer">
    <div class="componentWrapper">
        <h3 class="fieldTitle">{{fieldTitle}}</h3>
        <div class="comparisonElements">
            <span class="comparisonDate">{{comparisonDate}}</span>
            &nbsp;
            <span class="comparisonValue">{{comparisonValue}}</span>
        </div>
        <div class="currentDate" v-if="currentDate">{{currentDate}}</div>

        <!-- <center-template></center-template> -->

        <!-- chart component -->
        <div class="pies-container">
            <DxPieChart
                id="pie"
                :data-source="populationByRegions"
                type="doughnut"
                palette="Soft Pastel"
                :inner-radius="0.75"
                center-template="centerTemplate"
            >
                <DxMargin :right="50"/>
                <DxSeries argument-field="region">
                <DxLabel
                    :visible="false"
                    format="millions"
                >
                    <DxConnector :visible="false"/>
                </DxLabel>
                </DxSeries>
                <DxExport :enabled="false"/>
                <DxLegend
                :margin="0"
                horizontal-alignment="left"
                vertical-alignment="bottom"
                :visible="true"
                />
                <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltip"
                >
                <DxFormat
                    type="millions"
                />
                </DxTooltip>
                <template #centerTemplate="data">
                    <CenterTemplate :pie-chart="data.data"/>
                </template>
            </DxPieChart>
        </div>
        <!-- /chart component -->

    </div>
  </div>
</template>

<script>
    // PieChart
    import { populationByRegions } from '@/data-pieChart.js';
    import DxPieChart, {
    DxLegend,
    DxSeries,
    DxTooltip,
    DxFormat,
    DxLabel,
    DxConnector,
    DxExport,
    DxMargin
    } from 'devextreme-vue/pie-chart';
    import CenterTemplate from '@/components/doughnut-visualizer/partials/CenterTemplate.vue';

    // import { DxPieChart, DxSeries, DxExport, DxTooltip, DxLegend, DxLabel, DxConnector } from 'devextreme-vue/pie-chart';

    // /PieChart
    export default { 
        components: {
            DxPieChart,
            DxLegend,
            DxSeries,
            DxTooltip,
            DxFormat,
            DxLabel,
            DxConnector,
            DxExport,
            DxMargin,
            CenterTemplate
            // DxPieChart, DxSeries, DxExport, DxTooltip, DxLegend, DxLabel, DxConnector, CenterTemplate
        },
        data() {
            return {
                fieldTitle: "Occupancy",
                comparisonDate: "Agosto 2019",
                comparisonValue: "41%",
                currentDate: "Agosto 2020",
                currentValue: "43%",
                gain: "+2%",

                // PieChart
                // data: data,
                // countries: Array.from(new Set(data.map(item => item.country)))
                populationByRegions,
                // /PieChart
            }
        },
        methods: {
            customizeTooltip({ valueText, percent }) {
                return {
                    text: `${valueText} - ${(percent * 100).toFixed(2)}%`
                };
            },
            // getData(country) {
            // return data.filter(i => i.country === country);
            // }
        }
    }
</script>

<style lang="scss" scoped>

.componentContainer {
  width: 100%;

  .componentWrapper {
    width: 300px;
    height: 211px;
    border-radius: 11px;
    font-family: 'Nunito', sans-serif;
    background-color: white;
    padding: 21px 32px;
    margin: 0 auto;

    .fieldTitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 17px;
      color: #000;
      margin-top: 0;
      margin-bottom: 6px;
    }

    .comparisonElements {
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 10px;

      .comparisonDate {
        color: #a3a3a3;
      }

      .comparisonValue {
        color: #535353;
      }
    }

    .currentDate {
      font-weight: 600;
      line-height: 19px;
      font-size: 14px;
      color: #a3a3a3;
      text-align: center;
      margin-bottom: 5px;
    }

    // .currentValue {
    //     font-weight: 400;
    //     font-size: 40px;
    //     line-height: 54px;
    //     text-align: center;
    // }

    // .gain {
    //     color: #16810d;
    //     font-size: 18px;
    //     font-weight: 600;
    //     text-align: center;
    // }
  }

  // PieChart

  .pies-container {
    // margin: auto;
    // width: 800px;


    #pie {
      height: 96px;

      /*  position: relative;
                
            svg.dxc-chart {
                position: relative;
                
                g.dxc-series-group {
                    bottom: 0;
                    left: -100px !important;
                }

                g.dxc-legend {
                    position: absolute;
                }
            } */


    }


    /* & > div {
            position: relative;
            width: 196px;
            height: 196px;
        float: left;
        // margin-top: -50px;
        outline: 1px dotted lightblue;
        margin: 0 auto;

            svg {
                position: relative;
            .dxc-legend {
                position: absolute;
                bottom: 0;
            }

            .dxc-series-group {
                position: absolute;
                // transform: translateX(-50%);
                left: 50px;
            }
            }

        } */
  }

  // /PieChart
}
</style>