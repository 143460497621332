<template>
      <div class="wrapper-paddings">
    <div class="wrapper">
        <h3 class="fieldTitle">{{ fieldTitle }}</h3>
        <div class="comparisonElements"><span class="comparisonDate">{{comparisonDate}}</span> <span class="comparisonValue">{{comparisonValue}}</span></div>
        <div class="currentDate">{{currentDate}}</div>
        <slot name="chart"></slot>
    </div>
      </div>
</template>

<script>
    export default {
      props: ["fieldTitle", "comparisonDate", "comparisonValue", "currentDate", "currentValue", "gain"],
        data() {
            return {

            }
        },
    }
</script>

<style lang="scss" scoped>
.wrapper-paddings {
  padding: 10px;
}
    .wrapper {
        width: 100%;
        //height: 250px !important;
        border-radius: 11px;
        font-family: 'Nunito', sans-serif;
        background-color: white;
        padding: 21px 32px;
        //margin: 10px;

        .fieldTitle {
            font-weight: 600;
            font-size: 20px;
            line-height: 17px;
            color: #000;
            margin-top: 0;
            margin-bottom: 6px;
        }

        .comparisonElements {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 23px;

            .comparisonDate {
                color: #a3a3a3;
            }

            .comparisonValue {
                color: #535353;
            }
        }

        .currentDate {
            font-weight: 600;
            line-height: 19px;
            font-size: 12px;
            color: #a3a3a3;
            text-align: center;
        }

    }
    @media screen and (max-width: 1024px) {
      .wrapper{
        //height: 420px !important;

        .fieldTitle {
          font-weight: 400;
          font-size: 15px;
          line-height: 17px;
          color: #000;
          margin-top: 0;
          margin-bottom: 6px;
        }


        .comparisonElements {
          font-weight: 600;
          line-height: 19px;
          font-size: 12px;
          color: #a3a3a3;

        }

        .componentDoughnut {

        }

      }
    }
    @media screen and (max-width: 768px) {
      .wrapper {
        //height: 300px !important;
      }
    }
</style>