<template>
  <router-link v-if="tenantData.tenantId >= 0" :to="{ name: 'tenant-page', params: { tenant: tenantData.tenantId }, query: { isFromOutside: true }}">
    <div class="state-tooltip">
      Tenant: {{ tenantData.tenant == null ? "-" : tenantData.tenant }}<br>
      GLA: sqm {{ info.argumentText }}<br>
      MBR/sqm: € {{ info.valueText }}
    </div>
  </router-link>
  <div class="state-tooltip" v-else>
    Tenant: {{ tenantData.tenant == null ? "-" : tenantData.tenant }}<br>
    GLA: sqm {{ info.argumentText }}<br>
    MBR/sqm: € {{ info.valueText }}
  </div>
</template>
<script>

export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tenantData: this.info.point.data,
    }
  },
  mounted() {
    // console.log("prova info", this.info)
  }

};
</script>
<style>
a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.state-tooltip {
  text-decoration: none !important;
  color: #000 !important;
  padding: 15px;
  margin: -15px !important;
}

/*.state-tooltip > img {*/
/*  width: 60px;*/
/*  height: 40px;*/
/*  display: block;*/
/*  margin: 0 5px 0 0;*/
/*  float: left;*/
/*  border: 1px solid rgba(191, 191, 191, 0.25);*/
/*}*/

/*.state-tooltip > h4 {*/
/*  line-height: 40px;*/
/*  font-size: 14px;*/
/*  margin-bottom: 5px;*/
/*}*/

/*.state-tooltip .caption {*/
/*  font-weight: 500;*/
/*}*/

/*.state-tooltip sup {*/
/*  font-size: 0.8em;*/
/*  vertical-align: super;*/
/*  line-height: 0;*/
/*}*/
</style>
