<template>
  <div
    class="dx-swatch-additional side-navigation-menu"
    @click="forwardClick"
  >
    <slot />
    <div class="menu-container">
      <dx-tree-view
        :ref="treeViewRef"
        :items="items"
        key-expr="path"
        selection-mode="single"
        :focus-state-enabled="false"
        expand-event="click"
        @item-click="handleItemClick"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import DxTreeView from "devextreme-vue/ui/tree-view";
import { sizes } from '../utils/media-query';
import navigation from '../app-navigation';

const treeViewRef = "treeViewRef";
const isLargeScreen = sizes()['screen-large'];
const items = navigation.map((item) => {
        // console.log("treeview item path", item.path)
      if(item.path && !(/^\//.test(item.path))){
        // console.log("!path called")
        item.path = `/${item.path}`;
      }
      return {...item, expanded: isLargeScreen}
    });

export default {
  props: {
    compactMode: Boolean
  },
  data() {
    return {
      treeViewRef,
      items
    };
  },
  methods: {
    forwardClick(...args) {
      this.$emit("click", args);
    },

    handleItemClick(e) {
      // if (!e.itemData.path || this.compactMode) { // originale
      //   return;
      // }

      if (!e.itemData.path) { // modificato per far sì che la barra non usi il primo click per aprire ma mandi subito alla pagina richiesta
        return;
      }

      this.$router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    },

    updateSelection() {
      if (!this.treeView) {
        return;
      }
      // console.log("treeview route path", this.$route.params)

      // #: condizione aggiunta per illuminare icona tenant in sidebar anche quando si arriva alla pagina da tooltip
      if(typeof this.$route.params.tenant == "number") {
        this.treeView.selectItem("/tenants/all-tenants-page");
      } else {
        this.treeView.selectItem(this.$route.path); // originale
      }
      // /

      // this.treeView.selectItem(this.$route.path); // originale
      this.treeView.expandItem(this.$route.path);
    }
  },
  mounted() {
    this.treeView = this.$refs[treeViewRef] && this.$refs[treeViewRef].instance;
    // console.log("treeview", this.treeView)
    this.updateSelection();
    if (this.compactMode) {
      this.treeView.collapseAll();
    }

  },
  watch: {
    $route() {
      this.updateSelection();
    },
    compactMode() {
      if (this.compactMode) {
        this.treeView.collapseAll();
      } else {
        this.updateSelection();
      }
    }
  },
  components: {
    DxTreeView
  }
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 150px !important; // MY-CUSTOM
  // width: 250px !important;
  background-color: #015560 !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
        // #sidemenu_icons
        .dx-icon-customMain {
            background-image: url('./../assets/sidemenu/chart-line.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-customHome {
            background-image: url('./../assets/sidemenu/home.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-effortRateIco {
            background-image: url('./../assets/sidemenu/effort-rate.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-portfolioIco {
            background-image: url('./../assets/sidemenu/portfolio.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-footfallIco {
            background-image: url('../assets/sidemenu/footfall.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-netSalesIco {
            background-image: url('../assets/sidemenu/net-sales.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-turnoverIco {
            background-image: url('../assets/sidemenu/turnover.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .dx-icon-tenantIco {
          background-image: url('../assets/sidemenu/tenant.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
      //  /
      }
      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          // MY-CUSTOM
          // border-bottom: 1px solid $base-border-color;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }


          // ORIGINAL
          // &.dx-state-selected > .dx-treeview-item * {
            //   color: $base-accent;
          // }

          // MY-CUSTOM
          &.dx-state-selected > .dx-treeview-item {
            position: relative;
          }

          &.dx-state-selected > .dx-treeview-item:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            background-color: white;
            // background-color: $base-accent;
          }
          // /

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: lighten(#015560, 4);
            // background-color: lighten($base-bg, 4);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
    // ##
  }
}
</style>
