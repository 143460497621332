<template>
    <div class="wrapper-paddings">
  <div class="componentWrapper">
    <h3 class="componentTitle">{{componentTitle}}</h3>
    <div class="midSectionContainer">
<!--      <div class="dataDate" v-if="dataDate">{{dataDate}}</div>-->
<!--      <div class="exportMenuContainer">-->
<!--        <slot name="componentExportMenu" class="exportMenu">...</slot>-->
<!--      </div>-->
    </div>

    <div class="componentTabContainer componentDataContainer">
      <slot name="componentTab" class="componentTab"></slot>
    </div>
  </div>
    </div>
</template>

<script>
export default {
  props:["componentTitle", "dataDate", "dataReady"],

  data() {
    return {
      dataDisplayMode: 'chart',
    }
  },

  methods: {
    displayDataSwitch(value) {
      this.dataDisplayMode = value;
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-paddings {
  padding: 10px;
}

.activeSwitch {
  color: #AA192F !important;
}
//420->531
.componentWrapper {
  background-color: white;
  border-radius: 11px;
  border: none;
  padding: 21px 32px;
  //margin: 10px;
  height: 531px !important;

  font-family: Nunito, sans-serif;

  .componentTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    color: #000;
    margin-top: 0;
    margin-bottom: 6px;
  }

  .midSectionContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

      .dataDate {
        font-weight: 600;
        line-height: 19px;
        font-size: 12px;
        color: #a3a3a3;
        text-align: center;
        margin-bottom: 5px;
      }

      .exportMenuContainer {
        //margin-left: 32px;
      }

  }

  .componentDataContainer > * {
    //outline: 1px solid lightblue;
    height: 422px;
    overflow: auto;
    //width: 100%;
  }


}
@media screen and (max-width: 1024px) {
  .componentWrapper{
    height: 420px !important;

    .componentTitle {
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #000;
      margin-top: 0;
      margin-bottom: 6px;
    }

    .midSectionContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;




        .dataDate {
          font-weight: 400;
          line-height: 19px;
          font-size: 12px;
          color: #787878;
          text-align: center;
          margin-bottom: 5px;
        }

        .exportMenuContainer {
          margin-left: 32px;
        }



    }

    .componentDataContainer > * {
      //outline: 1px solid lightblue;
      height: 312px;
      overflow: auto;
      //width: 100%;
    }

  }
}
@media screen and (max-width: 768px) {
  .componentWrapper {
    //height: 300px !important;
  }
}
</style>