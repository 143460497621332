<template>
  <svg>
    <circle
        :cx="6"
        :cy="6"
        :r="6"
        :fill="color"
        :opacity="1"
    />
    <path
        :fill="color"
    />
  </svg>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      color: this.item.marker.fill,
    };
  },
};
</script>
