<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar container">
      <!-- pulsante menu in dxtoolbar: visibile quando mobile -->
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
        </template>
      </dx-item>

      <!-- <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
        <template>
          <div>{{ title }}</div>
        </template>
      </dx-item> -->

      <dx-item location="before">
        <img
          src="@/assets/images/logo_main.png"
          alt="logo"
          width="100px"
          height="auto"
        />
      </dx-item>

      <!-- menu user auth e profile -->
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <!-- desktop -->
        <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel
                :user="user"
                :menu-items="userMenuItems"
                menu-mode="context"
              />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <!-- mobile -->
      <template #menuUserItem>
        <user-panel :user="user" :menu-items="userMenuItems" menu-mode="list" />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";

import UserPanel from "./user-panel";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function,
  },
  created() {
    auth.getUser().then((e) => (this.user = e.data));
  },
  data() {
    return {
      user: {},
      userMenuItems: [
        // {
        //   text: "Profile",
        //   icon: "user",
        //   onClick: this.onProfileClick
        // },
        {
          text: "Logout",
          icon: "runner",
          onClick: this.onLogoutClick,
        },
      ],
    };
  },
  methods: {
    onLogoutClick() {
      auth.logOut();
      // this.$router.push({
      //   path: "/login-form",
      //   query: { redirect: this.$route.path }
      // });
    },
    onProfileClick() {
      this.$router.push({
        path: "/profile",
        query: { redirect: this.$route.path },
      });
    },
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: white;

  .dx-toolbar {
    // max-width: 1400px;
    // margin: 0 auto;

    .dx-toolbar-before {
      padding-left: 20px;
    }
  }

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

// .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
//   padding: 0 40px;

//   .screen-x-small & {
//     padding: 0 20px;
//   }
// }

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.dx-button .dx-button-content {
  padding: 0 !important;
}
</style>
