<template>
    <div style="height: 100%; width: 30px">
      <router-link style="display: inline-block; height: 100%; width:100%; position:relative" :to="{ name: 'tenant-page', params: { tenant: data.value }, query: { isFromOutside: true } }">
        <span style="position: absolute; bottom: 9px">
          <img src="@/assets/images/file_img.png" alt="">
        </span>
      </router-link>
    </div>
</template>
<script>

export default {

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

};
</script>
<style lang="scss" scoped>


</style>
