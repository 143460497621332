<template>
  <div class="wrapper-paddings">
    <div class="componentWrapper">
      <h3 class="componentTitle">{{componentTitle}}</h3>

      <div class="legend">

        <div class="legendItem" v-for="(item, ix) in legend" :key="ix">
          <span class="legendItem-dot"></span>
          <span class="legendItem-text">{{ item }}</span>
        </div>

<!--        <div class="legendItem">-->
<!--          <span class="legendItem-dot"></span>-->
<!--          <span class="legendItem-text">{{ loc[locObj].BARGAUGELEGENDGOOD + legend[legendProp.good] }}</span>-->
<!--        </div>-->
<!--        <div class="legendItem">-->
<!--          <span class="legendItem-dot"></span>-->
<!--          <span class="legendItem-text">+{{ legend[legendProp.good] + loc[locObj].BARGAUGELEGENDNORMAL + legend[legendProp.bad] }}</span>-->
<!--        </div>-->
<!--        <div class="legendItem">-->
<!--          <span class="legendItem-dot"></span>-->
<!--          <span class="legendItem-text">{{ loc[locObj].BARGAUGELEGENDBAD + legend[legendProp.bad] }}</span>-->
<!--        </div>-->
      </div>

      <div class="componentDoubleDoughnutContainer">

        <div class="componentDoughnutContainer">
          <div class="comparisonDate date-style" v-if="comparisonDate">{{comparisonDate}}</div>
          <slot name="componentDoughnut2" class="componentDoughnut"></slot>
        </div>

        <div class="componentDoughnutContainer">
          <div class="refDate date-style" v-if="refDate">{{refDate}}</div>
          <slot name="componentDoughnut1" class="componentDoughnut"></slot>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { loc } from "@/config/languages/en.js";
export default {
  props:["componentTitle", "refDate", "dataReady", "comparisonDate", "legend", "domain"],

  data() {
    return {
      loc: loc,

      dataDisplayMode: 'chart',

      // locObj: this.domain == 'rent_svc' ? 'RENTSERVICES' : 'EFFORTRATE',
      // legendProp: this.domain == 'rent_svc' ? { good: 'higherPerformanceThreshold', bad: 'lowerPerformanceThreshold' } : { good: 'goodNormalThreshold', bad: 'normalBadThreshold' },
    }
  },

  methods: {
    displayDataSwitch(value) {
      this.dataDisplayMode = value;
    }
  }
}
</script>

<style lang="scss" scoped>

/***************
* # component
***************/
// ! IMPORTANTE: per agire su altezza componente, impostare altezza gauge da pagina in cui si trova (anche relative media query) !

//.wrapper-paddings {
//  padding: 10px;
//}

//420->531
.componentWrapper {
  //background-color: white;
  //border-radius: 11px;
  //border: none;
  ////padding: 21px 32px;
  //padding: 27px 32px;
  ////height: 531px !important;
  //
  ////font-family: Nunito, sans-serif;

  .componentTitle {
    //font-weight: 600;
    //font-size: 20px;
    //line-height: 17px;
    //color: #000;
    //margin-top: 0;
    //margin-bottom: 30px;
  }

  .componentDoubleDoughnutContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .componentDoughnutContainer {
      //flex-basis: 50%;
      width: 50%;

      .comparisonDate, .refDate {
        //font-weight: 400;
        //line-height: 19px;
        //font-size: 14px;
        //color: #a3a3a3;
        //text-align: center;
        //margin-top: 35px;
        margin-bottom: 15px;
        //text-align: center;
        //color: #666666;
      }


    }
  }

  .legend {

    display: flex;
    //justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    max-width: 500px;
    //margin: 5px auto 0;
    margin-top: 5px;

    .legendItem {
      margin-right: 15px;

      .legendItem-dot {
        //display: inline-block;
        display: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: none;
        vertical-align: sub;
        margin-right: 5px;
      }

      .legendItem-text {
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        color: #666666;
        vertical-align: sub;
      }

    }
    .legendItem:nth-child(1) .legendItem-dot {
      background-color: #59C47F; // #var scss good
    }

    .legendItem:nth-child(2) .legendItem-dot {
      background-color: #FFD341; // #var scss normal
    }

    .legendItem:nth-child(3) .legendItem-dot {
      background-color: #FF5633; // #var scss bad
    }
  }


}
@media screen and (max-width: 1024px) {

  /*************** 
  * # component 
  ***************/
  .componentWrapper{
    //height: 319px !important;

    


      //.comparisonDate, .refDate {
      //  font-weight: 600;
      //  line-height: 19px;
      //  font-size: 12px;
      //  color: #a3a3a3;
      //  text-align: center;
      //  margin-bottom: 5px;
      //}

    .componentDoughnutContainer {
      //height: 150px !important;
      //overflow: hidden;
      //background-color: blue !important;
    }

    .legend {

      //display: flex;
      //justify-content: space-between;
      //align-items: center;
      //max-width: 500px;
      //height: 30px;
      //margin: 5px auto 0;
      //background-color: orange;
      //padding: 5px;

      .legendItem {

        .legendItem-dot {
          //display: inline-block;
          //width: 12px;
          //height: 12px;
          //border-radius: 50%;
          //border: none;
          //vertical-align: sub;
          //margin-right: 5px;
        }

        .legendItem-text {
          font-size: 12px;
          line-height: 16px;
          //font-weight: 300;
          color: #666666;
          //vertical-align: sub;
        }

      }

    }
  }
}
@media screen and (max-width: 768px) {
  
  /*************** 
  * # component 
  ***************/

  .componentWrapper{
    //height: 319px !important;

    


    //.comparisonDate, .refDate {
    //  font-weight: 600;
    //  line-height: 19px;
    //  font-size: 12px;
    //  color: #a3a3a3;
    //  text-align: center;
    //  margin-bottom: 5px;
    //}

    .componentDoughnutContainer {
      //height: 150px !important;
      //overflow: hidden;
      //background-color: blue !important;
    }

    .legend {

      //display: flex;
      //justify-content: space-between;
      //align-items: center;
      //max-width: 500px;
      //height: 30px;
      //margin: 5px auto 0;
      //background-color: orange;
      //padding: 5px;

      .legendItem {

        .legendItem-dot {
          //display: inline-block;
          //width: 12px;
          //height: 12px;
          //border-radius: 50%;
          //border: none;
          //vertical-align: sub;
          //margin-right: 5px;
        }

        .legendItem-text {
          //font-size: 12px;
          //line-height: 16px;
          //font-weight: 300;
          //color: #666666;
          //vertical-align: sub;
        }

      }

    }
  }


}
</style>