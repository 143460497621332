<template>
  <div style="height: 100%; width: 30px;">
    <div style="display:flex; height: 100%; width:100%; align-items: center">
        <span style="">
          <img v-if="data.text == 'true'" src="@/assets/images/check-solid-grey.png" alt="" style="width: 17px;">
          <div v-else src="#" alt="" style="width: 17px; height: 23px"></div>
        </span>
    </div>
  </div>
</template>
<script>

export default {

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

};
</script>
<style lang="scss" scoped>


</style>
