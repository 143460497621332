import Vue from 'vue'
import Vuex from 'vuex'
// import ApiCalls from "@/services/ApiCalls";

Vue.use(Vuex)

export default new Vuex.Store({
// const store =  new Vuex.Store({
  state: {

    /** ## version **********/
    appVersion: null,

    /** ## token **********/
    accessToken: null,

    /** ## common **********/
    legendFontSettings: {
      size: 14,
      weight: 300,
      color: "#666666",
    },

    legendColors: {
      colorVacant: "#666666",
      colorGood: "#59C47F",
      colorNormal: "#FFD341",
      colorBad: "#FF5633",
    },

    /** ## outlets **********/
    outletsList: [],
    selectedOutlet: null,
    outletTertiaryColor: "#26B6C1", // uguale per tutti gli outlet

     /** ## categorie merceologiche **********/
    categoriesList: [],
    selectedCategory: 0,

     /** ## dates **********/
    outletLastSalesDates: {},
    newOutletLastSalesDates: {},
    selectedDate: null,
    selectedSettings: null,

     /** ## domain **********/
     currentDomain: "", // #n

     /** ## pagine (domain) con filtri disabilitati **********/ // #n
     pagesDisabledFilters: {
       productCategoryFilter: ["MAIN_PANEL", "OCCUPANCY"], // pagine in cui il filtro categorie merceologiche è disabilitato
       // calendar: { // ancora da implementare
       //   week: [],
       //   year: [],
       // },
     },

    /** ## tenants **********/
    tenantsList: [],
    selectedTenant: null,

    /** ## er colors **********/
    erColors: {
      colorGood: "#59C47F",
      colorNormal: "#FFD341",
      colorBad: "#FF5633",
    }

  },
  mutations: {
    /***** ### version *****/
    SET_APP_VERSION(state, version) {
      state.appVersion = version;
      console.debug("set vuex app version: ", this.state.appVersion);
    },

    /***** ### token *****/
    SET_ACCESS_TOKEN(state, aToken) {
      state.accessToken = aToken;
      // console.log("set vuex access token: ", this.state.accessToken);
    },

    /***** ### outlets *****/
    SET_OUTLETS_LIST(state, outlets) {
      state.outletsList = [...outlets];
      // console.log("set vuex outlet list: ", this.state.outletsList);
    },
    SET_SELECTED_OUTLET(state, outlet) {
      state.selectedOutlet = outlet;
      // console.log("set vuex selected outlet: ", state.selectedOutlet)
    },

    /***** ### categorie merceologiche *****/
    SET_CATEGORIES_LIST(state, categories) {
      state.categoriesList = [...categories];
      // console.log("set vuex categories list: ", this.state.categoriesList);
    },

    SET_SELECTED_CATEGORY(state, category) {
      state.selectedCategory = category;
      console.log("set vuex selected category: ", state.selectedCategory)
    },

    /***** ### last sales dates per outlet *****/
    SET_OUTLETLASTSALES_DATES(state, {salesLastDay, salesLastDayClosedWeek, salesLastDayClosedMonth}) { // old
      state.outletLastSalesDates = {salesLastDay, salesLastDayClosedWeek, salesLastDayClosedMonth};
      // console.log("set vuex outlet last sales date: ",this.state.outletLastSalesDates)
    },

    NEW_SET_OUTLETLASTSALES_DATES(state, {firstAvailableSalesDate, lastAvailableSalesDate}) {
      state.newOutletLastSalesDates = {firstAvailableSalesDate, lastAvailableSalesDate};
      // console.log("set vuex outlet last sales date: ",this.state.outletLastSalesDates)
    },

    SET_SELECTED_DATE(state, date) {
      state.selectedDate = date;
      console.log("set vuex selected date: ", state.selectedDate)
    },

    SET_SELECTED_SETTINGS(state, date) {
      state.selectedSettings = date;
      console.log("set vuex selected settings: ", state.selectedSettings)
    },

    /***** ### domain *****/ // #n
    SET_CURRENT_DOMAIN(state, domain) {
      state.currentDomain = domain;
      console.log("set vuex current domain: ", this.state.currentDomain)
    },

    /***** ### tenants *****/
    SET_TENANTS_LIST(state, tenants) {
      state.tenantsList = [...tenants];
      console.log("set vuex tenants list: ", this.state.tenantsList);
    },
    SET_SELECTED_TENANT(state, tenant) {
      state.selectedTenant = tenant;
      console.log("set vuex selected tenant: ", state.selectedTenant)
    },

  },

  actions: {
    /***** ### version *****/
    dispatchAppVersion({commit}, version) {
      commit("SET_APP_VERSION", version)
    },

    /***** ### token *****/
    dispatchAccessToken({commit}, aToken) {
      commit("SET_ACCESS_TOKEN", aToken)
    },

    /***** ### outlets *****/
    dispatchOutletsList({commit}, outletsList) {
      commit("SET_OUTLETS_LIST", outletsList)
    },
    dispatchSelectedOutlet(context, outlet) {
      context.commit("SET_SELECTED_OUTLET", outlet);
    },

    /***** ### categorie merceologiche *****/
    dispatchCategoriesList({commit}, categoriesList) {
      commit("SET_CATEGORIES_LIST", categoriesList)
    },

    dispatchSelectedCategory({commit}, category) {
      commit("SET_SELECTED_CATEGORY", category);
    },

    /***** ### last sales dates per outlet *****/
    dispatchOutletLastSalesDates({commit}, outletLastSalesDates) {
      commit("SET_OUTLETLASTSALES_DATES", outletLastSalesDates)
    },

    dispatchNewOutletLastSalesDates({commit}, outletLastSalesDates) {
      commit("NEW_SET_OUTLETLASTSALES_DATES", outletLastSalesDates)
    },

    dispatchSelectedDate({commit}, date) {
      commit("SET_SELECTED_DATE", date);
    },

    dispatchSelectedSetting({commit}, date) {
      commit("SET_SELECTED_SETTINGS", date);
    },

    /***** ### domain *****/ // #n
    dispatchCurrentDomain({commit}, domain) {
      commit("SET_CURRENT_DOMAIN", domain)
    },

    /***** ### tenants *****/
    dispatchTenantsList({commit}, tenantsList) {
      commit("SET_TENANTS_LIST", tenantsList)
    },

    dispatchSelectedTenant(context, tenant) {
      context.commit("SET_SELECTED_TENANT", tenant);
    },

  },
  modules: {
  },
  getters: {
    /***** ### version *****/
    getStoredAppVersion: state => {
      console.debug("vuex getter app version: ", state.appVersion);
      return state.appVersion;
    },

    /***** ### token *****/
    getAccessToken: state => {
      return state.accessToken;
    },

    /** ## common **********/
    getLegendFontSettings: state => {
      return state.legendFontSettings;
    },

     getLegendColors: state => {
      return state.legendColors;
    },

    /***** ### outlets *****/
    getOutletsList: state => {
      return state.outletsList;
    },

    getSelectedOutlet: state => {
      return state.selectedOutlet;
    },

    getOutletStyle: state => {
      return {
        // primaryColor: state.selectedOutlet.name == "Puglia Village" ? "#FFDB66" : state.selectedOutlet.primaryColor,
        primaryColor: state.selectedOutlet.primaryColor,
        secondaryColor: state.selectedOutlet.secondaryColor,
        tertiaryColor: state.selectedOutlet.tertiaryColor,
        logo: state.selectedOutlet.logo,
      }
    },

    /***** ### categorie merceologiche *****/
    getCategoriesList: state => state.categoriesList,

    /***** ### last sales dates per outlet *****/
    getOutletLastSalesDates: state => state.outletLastSalesDates, // old

    getNewOutletLastSalesDates: state => state.newOutletLastSalesDates,

    /***** ### tenants *****/
    getTenantsList: state => {
      return state.tenantsList;
    },

    /***** ### er colors *****/
    getErColors: state => {
      return state.erColors;
    },
  },

})

// export default store