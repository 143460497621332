<template>
  <div class="wrapper-paddings">
    <div class="componentWrapper">
      <h3 class="componentTitle">{{componentTitle}}</h3>

      <div class="midSectionContainer">
        <div class="componentDoughnutContainer">
          <slot name="componentDoughnut" class="componentDoughnut"></slot>
        </div>
      </div>

      <div class="legend">
        <div class="legendItem">
          <span class="legendItem-dot" :style="{
          'background-color': ((legendItemColor1) ? legendItemColor1 : '#F8B5BF')
          }"></span>
          <span class="legendItem-text">{{comparisonDate}}</span>
        </div>
        <div class="legendItem">
          <span class="legendItem-dot" :style="{
          'background-color': ((legendItemColor2) ? legendItemColor2 : '#FF5633')
          }"></span>
          <span class="legendItem-text">{{refDate}}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  //props:["componentTitle", "comparisonDate", "comparisonValue", "refDate", "refValue", "trendValue", "dataReady"],
  props:["componentTitle", "refDate", "comparisonDate", "legendItemColor1", "legendItemColor2"],

}
</script>

<style lang="scss" scoped>

/***************
* # component
***************/

//420->531
.componentWrapper {
  //background-color: white;
  //border-radius: 11px;
  //border: none;
  //padding: 21px 32px;
  //margin: 10px;
  //height: 531px !important;

  //font-family: Nunito, sans-serif;

  //.componentTitle {
  //  font-weight: 600;
  //  font-size: 20px;
  //  line-height: 17px;
  //  color: #000;
  //  margin-top: 0;
  //  margin-bottom: 6px;
  //}

  .comparisonDataContainer {

    .comparisonValue {
      margin-left: 10px;
      font-weight: 600;
    }
  }

  .midSectionContainer {
    //display: flex;
    //justify-content: space-between;
    //margin-bottom: 24px;
    margin-top: 10px;
    text-align: center;


    .refDate {
      //font-weight: 600;
      //line-height: 19px;
      //font-size: 12px;
      //color: #a3a3a3;
      //text-align: center;
      //margin-bottom: 5px;
    }

    .refValue {
      margin-top: 5px;
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 1px;
    }

    .trendValue {
      //margin-top: 5px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;

      .trendIcon {
        font-size: 0.8em;
        margin-right: 7px;
        vertical-align: top;
      }
    }

    .exportMenuContainer {
      //margin-left: 32px;
    }

  }

  .componentDataContainer > * {
    //outline: 1px solid lightblue;
    //height: 422px;
    overflow: auto; // #check
    //width: 100%;
  }

  .legend {

    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    //height: 30px;
    margin: 5px auto 0;
    //background-color: orange;
    //padding: 5px;

    .legendItem {

      .legendItem-dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: none;
        vertical-align: sub;
        margin-right: 5px;
      }

      .legendItem-text {
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        color: #666666;
        vertical-align: sub;
      }

    }

    /*.legendItem:nth-child(1) .legendItem-dot {
      background-color: #F8B5BF;
    }

    .legendItem:nth-child(2) .legendItem-dot {
      background-color: #FF5633;
    }*/
  }
}
@media screen and (max-width: 1024px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      //margin-bottom: 24px;
      margin-top: 5px;

      .refDate {
        font-size: 12px;
      }

      .trendValue {
        font-weight: 400;
        font-size: 15px;
      }

    }
    //----
  }


  //.componentDataContainer > * {
  //  //outline: 1px solid lightblue;
  //  height: 312px;
  //  overflow: auto;
  //  //width: 100%;
  //}


}
@media screen and (max-width: 768px) {

  /***************
  * # component
  ***************/
  .componentWrapper{

    .midSectionContainer {
      //margin-bottom: 24px;
      margin-top: 5px;

     .refValue {
       font-weight: 400;
       font-size: 25px;
     }



    }

  }
}
</style>