<template>
    <div class="wrapper">
        <h3 class="fieldTitle">{{ fieldTitle }}</h3>
        <div class="comparisonElements">
            <span class="currenDate">{{currentDate}}</span>
             | 
            <span class="comparisonDate">{{comparisonDate}}</span>
        </div>
        <div class="button-container">
            <button class="button">Reset Zoom</button>
        </div>
        <div class="chart-container">
            <DxChart 
                id="chart"
                :data-source="dataSource"
                palette="Violet"
                >
                <DxCommonSeriesSettings
                    :type="type"
                    argument-field="month"
                />
                <DxSeries
                    v-for="ffYear in ffYears"
                    :key="ffYear.value"
                    :value-field="ffYear.value"
                    :name="ffYear.name"
                />
                <DxMargin :bottom="20"/>
                <DxArgumentAxis
                    :value-margins-enabled="true"
                    discrete-axis-division-mode="crossLabels"
                >
                    <DxGrid :visible="true"/>
                </DxArgumentAxis>
                <DxLegend
                    vertical-alignment="bottom"
                    horizontal-alignment="center"
                    item-text-position="right"
                />
                <DxExport :enabled="false"/>
                <!-- <DxTitle text="Energy Consumption in 2004">
                    <DxSubtitle text="(Millions of Tons, Oil Equivalent)"/>
                </DxTitle> -->
                <DxTooltip :enabled="true"/>
            </DxChart>
        </div>
    </div>
</template>

<script>
    import {
        DxChart,
        DxSeries,
        DxArgumentAxis,
        DxCommonSeriesSettings,
        DxExport,
        DxGrid,
        DxMargin,
        DxLegend,
        // DxTitle,
        // DxSubtitle,
        DxTooltip
        } from 'devextreme-vue/chart';

        import service from '@/data-lineChart.js';

    export default {
         components: {
            DxChart,
            DxSeries,
            DxArgumentAxis,
            DxCommonSeriesSettings,
            DxExport,
            DxGrid,
            DxMargin,
            DxLegend,
            // DxTitle,
            // DxSubtitle,
            DxTooltip
        },
        data() {
            return {
                fieldTitle: "Fatturato netto - Andamento",
                currentDate: "Agosto 2020",
                comparisonDate: "Last year",

                // LineChart
                dataSource: service.getMonthsInfo(),
                ffYears: service.getFFYears(),
                type: 'line'
            }
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 837px;
        height: 558px;
        border-radius: 11px;
        font-family: 'Nunito', sans-serif;
        background-color: white;
        padding: 21px 32px;

        .fieldTitle {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #000;
            margin-top: 0;
            margin-bottom: 6px;
        }

        .comparisonElements {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            // margin-bottom: 23px;
            color: #787878;
        }

        .button-container {
            text-align: right;
            margin-bottom: 32px;

            .button {
                border-radius: 50px;
                width: 104px;
                border: 1px solid #000;
                background-color: #fff;
                padding: 3px 0;
            }
        }
    }

    .chart-container {
        // outline: 1px dotted lightblue;
        width: 732px;
        height: 330px;

        // Chart
        // .options {
        //     padding: 20px;
        //     background-color: rgba(191, 191, 191, 0.15);
        //     margin-top: 20px;
        // }

        // .option {
        //     margin-top: 10px;
        // }

        .caption {
            font-size: 18px;
            font-weight: 500;
        }

        // .option > span {
        //     margin-right: 14px;
        // }

        // .option > .dx-widget {
        //     display: inline-block;
        //     vertical-align: middle;
        // }
        // /Chart
    }
</style>