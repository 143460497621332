<template>
    <div class="wrapper">
        <h3 class="fieldTitle">{{ fieldTitle }}</h3>
        <div class="comparisonElements"><span class="comparisonDate">{{comparisonDate}}</span> <span class="comparisonValue">{{comparisonValue}}</span></div>
        <!-- <div class="currentDate">{{currentDate}}</div>
        <div class="currentValue">{{currentValue}}</div>
        <div class="gain">{{gain}}</div> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                fieldTitle: "Fatturato netto",
                comparisonDate: "Agosto 2019",
                comparisonValue: "12.154.7777",
                // currentDate: "Agosto 2020",
                // currentValue: "€13.145.230",
                // gain: "+8%",
            }
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 371px;
        height: 181px;
        border-radius: 11px;
        font-family: 'Nunito', sans-serif;
        background-color: white;
        padding: 21px 32px;

        .fieldTitle {
            font-weight: 600;
            font-size: 20px;
            line-height: 17px;
            color: #000;
            margin-top: 10px;
            margin-bottom: 6px;
        }

        .comparisonElements {
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            margin-bottom: 23px;

            .comparisonDate {
                color: #a3a3a3;
            }

            .comparisonValue {
                color: #535353;
            }
        }

        // .currentDate {
        //     font-weight: 600;
        //     line-height: 19px;
        //     font-size: 14px;
        //     color: #a3a3a3;
        //     text-align: center;
        // }

        // .currentValue {
        //     font-weight: 400;
        //     font-size: 40px;
        //     line-height: 54px;
        //     text-align: center;
        // }

        // .gain {
        //     color: #16810d;
        //     font-size: 18px;
        //     font-weight: 600;
        //     text-align: center;
        // }
    }
</style>