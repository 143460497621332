import axios from "axios";
import q from "querystring";
import router from "@/router";
import {custom} from "devextreme/ui/dialog";
const { baseURLRoot } = require('../../custom-config.js')

console.log('baseURLRoot', baseURLRoot)

const apiClient = axios.create({
    baseURL: baseURLRoot + "/api/v1",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application-json", // #! da modificare in application/json
    }
})

apiClient.interceptors.request.use(function (config) {
    //apiClient.defaults.headers.common['Authorization'] = "Bearer " + window.localStorage.getItem("accessToken");
    const token = window.localStorage.getItem('accessToken');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

apiClient.interceptors.response.use(response => response,
error => {

    if (error.response.status === 401) {

        if(error.response.data.error == "ExpiredAccessToken") {

            const url = error.response.config.url;
            const refresh_token = window.localStorage.getItem('refreshToken');

            return axios({
                method: "post",
                url: baseURLRoot + "/login",
                data: q.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: refresh_token
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
            .then(res => {

                window.localStorage.setItem("accessToken", res?.data?.access_token);
                window.localStorage.setItem("refreshToken", res?.data?.refresh_token);
                window.localStorage.setItem("refreshTokenExpiringDate", res?.data?.[".expires"]);

                return apiClient.get(url);

            })
            .catch(err => {

                console.log("Err catch", err);
                // router.push("/profile");
                // this.deleteLocalStorageItems();
                router.push("/login-form");
                window.localStorage.clear();
            })
        }

        return Promise.reject(error);
    }
});

const apiKey = "7xQvLsVpe*5sAoTKTwjP-qo!XPz-JKiUyMy5ye5z*E7wPmUtpMpaXhCwydtLct_S";

export default {
    //chiamata per recuperare versione app
    getAppVersion() {
        return apiClient.get(`/version?apikey=${apiKey}`)
    },

    // chiamata per recuperare timestamp last update
    getLastUpdateUTCTimestamp() {
      return apiClient.get(`/dataupdatestatus?apikey=${apiKey}`)
    },

    // chiamata per recuperare lista outlet
    getOutlets() {
        return apiClient.get(`/outlets?includePortfolio=true&apikey=${apiKey}`);
    },

    // chiamata per recuperare lista categorie mercelologiche, filtrata by outlet
    getCategories(outletId) {
        return apiClient.get(`/productcategories/${outletId}?apikey=${apiKey}` );
    },

    // chiamata per recuperare effortRate, filtrata by outlet e data
    getEffortRate(outletId, refDateYear, refDateMonth, yearComp) {
        return apiClient.get(`/effortrate/${outletId}?apikey=${apiKey}&year=${refDateYear}&month=${refDateMonth}&yearComp=${yearComp}`);
    },

    // chiamata per recuperare footfall, filtrara by outlet e data
    getFootfall(outletId, refDateYear, refDateMonth, yearComp) {
        return apiClient.get(`/footfall/${outletId}?apikey=${apiKey}&year=${refDateYear}&month=${refDateMonth}&yearComp=${yearComp}`);
    },

    // chiamata per recuperare occupacy, filtrara by outlet e data
    getOccupacy(outletId, refDateYear, refDateMonth, yearComp) {
        return apiClient.get(`/occupancy/${outletId}?apikey=${apiKey}&year=${refDateYear}&month=${refDateMonth}&yearComp=${yearComp}`);
    },

    // chiamata per recuperare net-sales, filtrara by outlet e data
    getNetSales(outletId, refDateYear, refDateMonth, yearComp) {
        return apiClient.get(`/sales/${outletId}?apikey=${apiKey}&year=${refDateYear}&month=${refDateMonth}&yearComp=${yearComp}`);
    },

    // chiamata per recuperare footfall, filtrara by outlet e data
    getMainPanel(outletId, refDateYear, refDateMonth, yearComp) {
        return apiClient.get(`/mainpanel/${outletId}?apikey=${apiKey}&year=${refDateYear}&month=${refDateMonth}&yearComp=${yearComp}`);
    },

    // chiamata per recuperare la lista dei tenant
    getTenants(outletId){
        return apiClient.get(`/tenants/${outletId}?apikey=${apiKey}`);
    },

    // chiamata per recuperare tenant-page, filtrara by outlet
    getTenantPage(outletId, tenantId) {
        return apiClient.get(`/tenantcard/${outletId}/${tenantId}?apikey=${apiKey}`);
    },

    // chiamata delete per logout
    delLogOut() {
        return apiClient.delete(`/Logout?apikey=${apiKey}`)
    },

    // chiamata per recuperare rent-services, filtrata by outlet e data
    getRentServices(outletId, refDateYear, refDateMonth, yearComp) {
        return apiClient.get(`/RentSvc/${outletId}?apikey=${apiKey}&year=${refDateYear}&month=${refDateMonth}&yearComp=${yearComp}`);
    },

    // // chiamata per recuperare effortRate, filtrata by outlet e product category - #comp1
    // getEffortRateByProductCategory(outletId, productCategoryId) {
    //     return apiClient.get(`/scatter/${outletId}?productcategoryid=${productCategoryId}`);
    // }
}
